import React, { useState, useEffect } from "react";
import { Button, Container, Row, Col, Form, Alert } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import * as Yup from "yup";
import { Formik } from "formik";
import SelectWrapperJson from '../common/SelectWrapperJson';

const ClientForm = () => {

  const [initialValues, setInitialValues] = useState({
    FirmaNazwa: '',
    Telefon: '',
    Email: '',
    Adres: '',
    KodPocztowy: '',
    Miejscowosc: '',
    NIP: '',
    KodKontrahenta: '',
    OsobaKontaktowa: '',
    PrzypisanieASMPM: '',
  });

  const { id } = useParams();
  const navigate = useNavigate();
  let infoContent = id === '0' ? 'Dodaj nowego klienta' : 'Edycja klienta';

  useEffect(() => {
    let isMounted = true;

    if (id > 0 || !id) {
      fetch(`/json/firma_${id}.json`)
        .then(response => response.json())
        .then(data => {
          if (isMounted) {
            setInitialValues({
              FirmaNazwa: data.FirmaNazwa || '',
              Telefon: data.Telefon || '',
              Email: data.Email || '',
              Adres: data.Adres || '',
              KodPocztowy: data.KodPocztowy || '',
              Miejscowosc: data.Miejscowosc || '',
              NIP: data.NIP || '',
              KodKontrahenta: data.KodKontrahenta || '',
              OsobaKontaktowa: data.OsobaKontaktowa || '',
              PrzypisanieASMPM: data.PrzypisanieASMPM || '',
            });
          }
        })
        .catch(error => {
          if (isMounted) {
            alert('Błąd podczas odbierania danych');
            navigate("/konsola/klienci/lista");
          }
        });
    }
    return () => {
      isMounted = false;
    };
  }, [id, navigate]);

  const validationSchema = Yup.object({
    FirmaNazwa: Yup.string().required('Nazwa klienta jest wymagana'),
    Telefon: Yup.string().required('Telefon jest wymagany').matches(/^[0-9()+\-\s]{9,}$/, 'Numer telefonu jest nieprawidłowy'),
    Email: Yup.string().required('Adres E-mail jest wymagany').email('Nieprawidłowy adres email'),
    Adres: Yup.string().required('Adres jest wymagany'),
    KodPocztowy: Yup.string(),
    Miejscowosc: Yup.string().required('Miejscowość jest wymagana'),
    NIP: Yup.string().required('NIP jest wymagany').matches(/^\b(?:\d{3}-\d{3}-\d{2}-\d{2}|\d{10})\b$/, 'Numer NIP jest nieprawidłowy'),
    KodKontrahenta: Yup.string().required('Kod kontrahenta jest wymagany'),
    OsobaKontaktowa: Yup.string(),
    PrzypisanieASMPM: Yup.string().required('Przypisanie ASM PM jest wymagane')
  });

  const onSubmit = async (values, { resetForm }) => {
    try {
      const response = await fetch('http://data.pl/klient', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(values)
      });
      if (!response.ok) {
        throw new Error('Wystąpił problem podczas wysyłania danych.');
      }
      resetForm();
      navigate("/konsola/klienci/lista");
    } catch (error) {
      console.error('Błąd podczas wysyłania danych:', error);
      alert('Wystąpił problem podczas wysyłania danych.');
    }
  };


  return (
    <Container style={{ border: '1px solid #ccc', padding: '20px' }}>
      <Alert variant="info" className="text-center mb-3"><b>{infoContent}</b></Alert>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Control
              type="hidden"
              name="IdKlient"
              value={id}
            />
            <Form.Group controlId="formUsername" className="mb-3">
              <Form.Label>Nazwa klienta</Form.Label>
              <Form.Control
                type="text"
                name="FirmaNazwa"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.FirmaNazwa}
                isInvalid={touched.FirmaNazwa && !!errors.FirmaNazwa}
              />
              {touched.FirmaNazwa && errors.FirmaNazwa && (
                <Form.Control.Feedback type="invalid">
                  {errors.FirmaNazwa}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Row className="mb-3">
              <Col>
                <Form.Group controlId="formPhone">
                  <Form.Label>Telefon</Form.Label>
                  <Form.Control
                    type="text"
                    name="Telefon"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.Telefon}
                    isInvalid={touched.Telefon && !!errors.Telefon}
                  />
                  {touched.Telefon && errors.Telefon && (
                    <Form.Control.Feedback type="invalid">
                      {errors.Telefon}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formEmail">
                  <Form.Label>E-mail</Form.Label>
                  <Form.Control
                    type="email"
                    name="Email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.Email}
                    isInvalid={touched.Email && !!errors.Email}
                  />
                  {touched.Email && errors.Email && (
                    <Form.Control.Feedback type="invalid">
                      {errors.Email}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Form.Group controlId="formAddress" className="mb-3">
              <Form.Label>Adres</Form.Label>
              <Form.Control
                type="text"
                name="Adres"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.Adres}
                isInvalid={touched.Adres && !!errors.Adres}
              />
              {touched.Adres && errors.Adres && (
                <Form.Control.Feedback type="invalid">
                  {errors.Adres}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Row className="mb-3">
              <Col xs={6}>
                <Form.Group controlId="formZipCode">
                  <Form.Label>Kod pocztowy</Form.Label>
                  <Form.Control
                    name="KodPocztowy"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.KodPocztowy}
                    isInvalid={touched.KodPocztowy && !!errors.KodPocztowy}
                  />
                  {touched.KodPocztowy && errors.KodPocztowy && (
                    <Form.Control.Feedback type="invalid">
                      {errors.KodPocztowy}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formCity">
                  <Form.Label>Miejscowość</Form.Label>
                  <Form.Control
                    name="Miejscowosc"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.Miejscowosc}
                    isInvalid={touched.Miejscowosc && !!errors.Miejscowosc}
                  />
                  {touched.Miejscowosc && errors.Miejscowosc && (
                    <Form.Control.Feedback type="invalid">
                      {errors.Miejscowosc}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Form.Group controlId="formNIP">
                  <Form.Label>NIP</Form.Label>
                  <Form.Control
                    name="NIP"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.NIP}
                    isInvalid={touched.NIP && !!errors.NIP}
                  />
                  {touched.NIP && errors.NIP && (
                    <Form.Control.Feedback type="invalid">
                      {errors.NIP}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formContractorCode">
                  <Form.Label>Kod kontrahenta</Form.Label>
                  <Form.Control
                    name="KodKontrahenta"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.KodKontrahenta}
                    isInvalid={touched.KodKontrahenta && !!errors.KodKontrahenta}
                  />
                  {touched.KodKontrahenta && errors.KodKontrahenta && (
                    <Form.Control.Feedback type="invalid">
                      {errors.KodKontrahenta}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formContactPerson">
                  <Form.Label>Osoba kontaktowa</Form.Label>
                  <Form.Control
                    name="OsobaKontaktowa"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.OsobaKontaktowa}
                    isInvalid={touched.OsobaKontaktowa && !!errors.OsobaKontaktowa}
                  />
                  {touched.OsobaKontaktowa && errors.OsobaKontaktowa && (
                    <Form.Control.Feedback type="invalid">
                      {errors.OsobaKontaktowa}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Form.Group controlId="formASMPM" className="mb-3">
              <Form.Label>Przypisanie ASM PM</Form.Label>
              <Form.Control
                as="select"
                name="PrzypisanieASMPM"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.PrzypisanieASMPM}
                isInvalid={touched.PrzypisanieASMPM && !!errors.PrzypisanieASMPM}
              >
                <SelectWrapperJson
                  json={'/json/produkty.json'}
                  firstOption='wybierz'
                  fieldOptionText = 'Nazwa'
                  fieldOptionValue = 'id'
                />
              </Form.Control>
              {touched.PrzypisanieASMPM && errors.PrzypisanieASMPM && (
                <Form.Control.Feedback type="invalid">
                  {errors.PrzypisanieASMPM}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Button variant="primary" type="submit" className="w-100 mt-2">
              Zapisz
            </Button>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default ClientForm;
