import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { dateToYYYYMMDDHHmm } from '../../../common/CommonFunction';

const smallerButtonStyle = {
    padding: '0.25rem 0.5rem',
    fontSize: '0.875rem',
};

const columns = [
    {
        name: 'id',
        selector: row => row.idQuestionnaire,
        sortable: true,
        filterable: true, 
        width: '5%',
    },
    {
        name: 'Tytuł',
        selector: row => row.questionnaireName,
        filterable: true,
        sortable: true,
        width: '40%',
        //cell: row => <input type="text" onChange={(e) => this.handleFilterChange(e.target.value, 'name')} />,
    },
    {
        name: 'Status',
        selector: row => row.questionnaireName,
        sortable: true,
        width: '10%',
    },
    {
        name: 'Statystyka',
        selector: row => <b>{row.questionnaireName}</b>,
        sortable: true,
        width: '10%',
    },
    {
        name: 'Data rozpoczęcia',
        selector: row => dateToYYYYMMDDHHmm(row.dataStart),
        sortable: true,
        width: '10%',
    },
    {
        name: 'Data zakończenia',
        selector: row => dateToYYYYMMDDHHmm(row.dataStop),
        sortable: true,
        width: '10%',
    },
    {
        name: 'Administracja',
        cell: row => (
            <Button variant="primary" style={smallerButtonStyle}>
                <Link
                    to={`/konsola/ankiety/${row.idQuestionnaire}`}
                    style={{ color: 'white', textDecoration: 'none' }}
                >
                    Edycja
                </Link>
            </Button>
        ),
    }
];

export default columns;
