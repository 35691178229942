import React from 'react';
import { useTranslation } from 'react-i18next';
import QuestionnaireTypeQuestionDependentQuestions from './QuestionnaireTypeQuestionDependentQuestions';

const QuestionnaireTypeQuestionInputFile = ({ index, questions, setQuestions }) => {

    const { t } = useTranslation();

    const handleCfgChange = (event, field) => {
        const updatedQuestions = [...questions];
        updatedQuestions[index]['cfg'][field] = event.target.value;
        setQuestions(updatedQuestions);
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                <div style={{ width: '120px' }}>{t('rodzaj pliku')}</div>
                <select
                    onChange={(event) => handleCfgChange(event, 'type')}
                    value={questions[index]?.cfg?.type}
                    className="form-control"
                >
                    <option value="photo">{t('zdjęcie')}</option>
                    <option value="movie">{t('film')}</option>
                    <option value="document">{t('dokument')}</option>
                </select>
            </div>
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                <div style={{ width: '120px' }}>{t('ilość')}</div>
                <select
                    onChange={(event) => handleCfgChange(event, 'quantity')}
                    value={questions[index]?.cfg?.quantity}
                    className="form-control"
                >
                    <option value="single">{t('pojedynczy')}</option>
                    <option value="many">{t('wiele')}</option>
                </select>
            </div>
            <QuestionnaireTypeQuestionDependentQuestions index={index} questions={questions} setQuestions={setQuestions} />
        </div>
    );
};

export default QuestionnaireTypeQuestionInputFile;
