import i18n from 'i18next';

export const dateToYYYYMMDDHHmm = (date) => {
  const formattedDate = date instanceof Date && !isNaN(date) ?
    `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${("0" + date.getDate()).slice(-2)} ${("0" + date.getHours()).slice(-2)}:${("0" + date.getMinutes()).slice(-2)}` :
    '';
  return formattedDate;
}

export const dateToYYYYMMDD = (date) => {
  const formattedDate = date instanceof Date && !isNaN(date) ?
    `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}` :
    '';
  return formattedDate;
}

export const truncateText = (text, maxLength = 100, separator = '...') => {
  if (typeof text !== 'string') {
    return '';
  }
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + separator;
  }
  return text;
}

export const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export const describeQuestionConfigKeyValue = (str) => {
  if (typeof str === 'string') {
    if (i18n.exists(str)) {
      return i18n.t(str);
    } else {
      return str;
    }
  } else if (!isNaN(parseInt(str))) {
    return str;
  } else if (Array.isArray(str)) {
    return str.map(item => item.variant).filter(Boolean).join(', ');
  } else {
    return i18n.t('brak rozpoznania') + ' -> ' + JSON.stringify(str);
  }
}