import React, { useState, useEffect } from 'react';
import DataTableBasejQuery from '../../datatable/table/base/DataTableBasejQuery';
import ClientListColumns from './ClientListColumns';
import { useNavigate } from 'react-router-dom';

function ClientList() {
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('/json/firmy.json');
                const json = await response.json();
                setData(json.data);
            } catch (error) {
                console.error('Błąd pobierania danych:', error);
            }
        };

        fetchData();
        setColumns(ClientListColumns);
    }, []);

    useEffect(() => {

        const handleClick = event => {
            const element = event.target.closest('.jqueryDataTable-link');
            if (element) {
                event.preventDefault();
                const path = element.getAttribute('data-href');
                navigate(path);
            }
        };

        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [navigate]);

    const handleNowyKlinet = () => {
        navigate('/konsola/klienci/edycja/0');
    }

    const aLengthMenu = [
        [10, 20, -1],
        ['Mała', 'średnia', 'wszystko']
    ]

    const columnDefs = [
        {
            visible: false,
            targets: [0, 3, 5]
        },
    ]

    return (
        <>
            <div className="d-flex justify-content-center mb-4">
                <button className="btn btn-outline-primary form-control" onClick={() => handleNowyKlinet()}>dodaj nowego klineta</button>
            </div>
            {data.length > 0 && columns.length && (
                <DataTableBasejQuery
                    columns={columns}
                    data={data}
                    aLengthMenu={aLengthMenu}
                    columnDefs={columnDefs}
                    showFooter={false}
                    localName='ClientList'
                />
            )
            }

        </>
    );
}

export default ClientList;
