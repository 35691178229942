import React, { useState, useEffect, useRef } from 'react';
import { defaultMaxVariantStringLength, defaultSelectMaxChecked, defaultSelectMaxSizeList } from '../QuestionnaireCfg';
import { useTranslation } from 'react-i18next';

const QuestionnaireTypeQuestionSelect = ({ index, questions, setQuestions }) => {

  const [minChecked, setMinChecked] = useState(questions[index]?.cfg?.minChecked || 0);
  const [maxChecked, setMaxChecked] = useState(questions[index]?.cfg?.maxChecked || 0);

  const { t } = useTranslation();
  const defaultVariantCfg = { variant: '' };

  const [variants, setVariants] = useState([defaultVariantCfg]);
  const [isNewVariantAdded, setIsNewVariantAdded] = useState(false);
  const newVariantRef = useRef(null);

  const updateVariant = (index, event) => {
    const updatedVariants = [...variants];
    updatedVariants[index].variant = event.target.value;
    setVariants(updatedVariants);
    setIsNewVariantAdded(false);
  };

  const addVariant = () => {
    const newVariants = [...variants, defaultVariantCfg];
    setVariants(newVariants);
    setIsNewVariantAdded(true);
  };

  const deleteVariant = (index) => {
    const newVariants = [...variants];
    newVariants.splice(index, 1);
    setVariants(newVariants);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      addVariant();
    }
  };

  useEffect(() => {
    if (questions[index]?.cfg?.variantCfg && questions[index].cfg.variantCfg !== variants) {
      setVariants(questions[index].cfg.variantCfg);
    }
  }, [index, questions]);

  useEffect(() => {
    const updatedQuestions = [...questions];
    updatedQuestions[index]['cfg'].variantCfg = variants;
    setQuestions(updatedQuestions);
  }, [variants, index]);

  const handleCfgChange = (event, field) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index]['cfg'][field] = parseInt(event.target.value, 10);
    setQuestions(updatedQuestions);

    if (field === 'minChecked') setMinChecked(parseInt(event.target.value, 10));
    if (field === 'maxChecked') setMaxChecked(parseInt(event.target.value, 10));
  };

  useEffect(() => {
    if (isNewVariantAdded && newVariantRef.current) {
      newVariantRef.current.focus();
    }
  }, [variants, isNewVariantAdded]);

  useEffect(() => {
    if (parseInt(minChecked, 10) > parseInt(maxChecked, 10)) {
      setMaxChecked(minChecked);
      const updatedQuestions = [...questions];
      updatedQuestions[index]['cfg'].maxChecked = minChecked;
      setQuestions(updatedQuestions);
    }
  }, [minChecked, maxChecked]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <div className="input-group mb-3">
        {variants.map((variant, index) => (
          <div key={index} className="input-group mb-3">
            <div style={{ width: '80px' }}>{t('wariant')}</div>
            <input
              ref={index === variants.length - 1 ? newVariantRef : null}
              id={`variant-${index}`}
              type="text"
              maxLength={defaultMaxVariantStringLength}
              value={variant.variant || ''}
              onChange={(event) => updateVariant(index, event)}
              onKeyPress={handleKeyPress}
              className="form-control"
            />
            <button
              type="button"
              onClick={() => deleteVariant(index)}
              className="btn btn-outline-secondary"
            >
              <i className="bi bi-trash"></i>
            </button>
          </div>
        ))}
      </div>
      <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <div style={{ width: '200px' }}>{t('min ilość wyboru')}</div>
        <input
          type="number"
          min={0}
          max={defaultSelectMaxChecked}
          value={minChecked}
          onChange={(event) => handleCfgChange(event, 'minChecked')}
          className="form-control"
        />
      </div>
      <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <div style={{ width: '200px' }}>{t('max ilość wyboru')}</div>
        <input
          type="number"
          min={0}
          max={defaultSelectMaxChecked}
          value={maxChecked}
          onChange={(event) => handleCfgChange(event, 'maxChecked')}
          className="form-control"
        />
      </div>
      <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <div style={{ width: '200px' }}>{t('rozmiar listy')}</div>
        <input
          type="number"
          min={1}
          max={defaultSelectMaxSizeList}
          value={questions[index]?.cfg?.sizeList}
          onChange={(event) => handleCfgChange(event, 'sizeList')}
          className="form-control"
        />
      </div>
      <button type="button" onClick={addVariant} className="btn btn-primary mr-1">
        {t('dodaj wariant')}
      </button>
    </div>
  );
};

export default QuestionnaireTypeQuestionSelect;