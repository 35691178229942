import FormModule from '../../cfg/ConstantStrings';

const QuestionnaireRenderQuestionShowOrHide = ({ event, question, questions, formik }) => {

  let foundEntriesHideIdField = [];

  const clearFormikData = (entry) => {
    entry.show = false;
    if (entry.type === FormModule.PICTURES) {
      for (let i = 0; i < entry.cfg.maxQuantity; i++) {
        const fieldNameIndex = `${entry.idField}_${i}`;
        formik.setFieldValue(fieldNameIndex, '');
      }
    } else {
      formik.setFieldValue(entry.idField, '');
    }
    foundEntriesHideIdField.push(entry.idField);
  }

  const handleChangeForm = () => {

    if (question.type === FormModule.RADIO) {
      questions.forEach(entry => {
        if (
          entry.show === false &&
          entry.ifReqField === question.idField &&
          entry.ifReqValue.includes(event.target.value)
        ) {
          entry.show = true;
        }
      });

      questions.forEach(entry => {
        if (
          entry.show === true &&
          entry.ifReqField === question.idField &&
          !entry.ifReqValue.includes(event.target.value)
        ) {
          clearFormikData(entry);
        }
      });

      while (questions.some(entry => (entry.show === true && foundEntriesHideIdField.includes(entry.ifReqField)))) {
        questions.forEach(entry => {
          if (
            entry.show === true &&
            foundEntriesHideIdField.includes(entry.ifReqField)
          ) {
            clearFormikData(entry);
          }
        });
      }

    } else if (question.type === FormModule.CHECKBOX) {

      if (event.target.checked) {

        questions.forEach(entry => {
          if (
            entry.show === false &&
            entry.ifReqField === question.idField &&
            entry.ifReqValue.includes(event.target.value)
          ) {
            entry.show = true;
          }
        })

      } else {

        const foundEntriesHide = questions.filter(entry => entry.show === true && entry.ifReqField === question.idField && entry.ifReqValue.includes(event.target.value));

        foundEntriesHide.forEach(entry => {
          let activeValues = formik.getFieldProps(question.idField).value
          activeValues = activeValues.filter(value => value !== event.target.value);

          if (!activeValues.some(value => entry.ifReqValue.includes(value))) {
            clearFormikData(entry);
          }
        });

        while (questions.some(entry => (
          entry.show === true &&
          foundEntriesHideIdField.includes(entry.ifReqField)
        ))) {
          questions.forEach(entry => {
            if (
              entry.show === true &&
              foundEntriesHideIdField.includes(entry.ifReqField)
            ) {
              clearFormikData(entry);
            }
          });
        }

      }

    } else if (question.type === FormModule.SELECT) {

      const selectedOptions = Array.from(event.target.selectedOptions).map(option => option.value);

      const foundEntriesShow = questions.filter(entry => entry.show === false && entry.ifReqField === question.idField && selectedOptions.some(option => entry.ifReqValue.includes(option)));

      foundEntriesShow.forEach(entry => {
        entry.show = true;
      });

      const foundEntriesHide = questions.filter(entry => entry.show === true && entry.ifReqField === question.idField && !selectedOptions.some(option => entry.ifReqValue.includes(option)));

      foundEntriesHide.forEach(entry => {
        entry.show = false;
      });

    }

  }

  return handleChangeForm()

};

export default QuestionnaireRenderQuestionShowOrHide;