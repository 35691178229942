import { fetchUserDetails } from '../../services/user';
import { createContext, useContext, useState, useEffect } from 'react';
import { AuthInterceptor } from '../../auth/AuthInterceptor';
import * as auth from '../../services/auth';

const UserContext = createContext(undefined);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [unregisterInterceptor, setUnregisterInterceptor] = useState(null);

  const handleReturnUserOrg = () => {
    setUser(user.orgCfg);
  };

  const handleChangeUserTmp = (userTmp) => {
    const userData = { ...userTmp };
    if (!user.orgUser && user.orgUser !== '') {
      userData.orgUser = user.username;
      userData.orgCfg = user;
    } else {
      userData.orgUser = user.orgUser;
      userData.orgCfg = user.orgCfg;
    }
    setUser(userData);
  };

  const handleLogin = async (username, password) => {
    await auth.signIn(username, password);
    await getCurrentUser();
  };

  const handleLogout = () => {
    auth.signOut();
    setUser(null);
    destroyAuthInterceptor();
  };

  const getCurrentUser = async () => {
    try {
      const idToken = await auth.getIdToken();
      if (idToken) {
        initAuthInterceptor(idToken);
        const userDetails = await fetchUserDetails();
        setUser(userDetails);
      } else {
        setUser(null);
      }
    } catch (err) {
      setUser(null);
      destroyAuthInterceptor();
    }
  };

  const handlePasswordChange = (newPassword) => {
    return Promise.resolve();
    // TODO use Cognito's reset pass approach - send an e-mail, then redirect to pass reset page
  };

  useEffect(() => {
    getCurrentUser()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  const initAuthInterceptor = (idToken) => {
    if (!unregisterInterceptor) {
      setUnregisterInterceptor(() => AuthInterceptor(idToken));
    }
  };

  const destroyAuthInterceptor = () => {
    if (unregisterInterceptor) {
      unregisterInterceptor();
    }
    setUnregisterInterceptor(null);
  };

  const getUserInfo = () => {
    if (user) {
      alert(`Zalogowany jako: ${user.username}, ${new Date(user.lastLogin).toString()}`);
    }
  };

  return (
    <UserContext.Provider value={{
      user,
      handleReturnUserOrg,
      onLogin: handleLogin,
      onLogout: handleLogout,
      onPasswordChange: handlePasswordChange,
      handleChangeUserTmp,
      userInfo: getUserInfo,
    }}>
      {!loading && children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};

export default UserContext;
