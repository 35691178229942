import React, { useState } from 'react';
import DataTable from 'react-data-table-component';

const columns = [
  {
    name: 'Title',
    selector: row => row.title,
    sortable: true,
  },
  {
    name: 'Year',
    selector: row => row.year,
    sortable: true,
  },
];

const data = [
  {
    id: 1,
    title: 'Beetlejuice',
    year: '1988',
  },
  {
    id: 2,
    title: 'Ghostbusters',
    year: '1984',
  },
];

function MyComponent() {
  const [hiddenColumns, setHiddenColumns] = useState([]); // Stan przechowujący ukryte kolumny

  const handleColumnToggle = (column) => {
    // Funkcja do obsługi ukrywania/odkrywania kolumn
    if (hiddenColumns.includes(column)) {
      setHiddenColumns(hiddenColumns.filter((col) => col !== column));
    } else {
      setHiddenColumns([...hiddenColumns, column]);
    }
  };

  const header = (
    <div>
      {columns.map((column) => (
        <div key={column.selector}>
          <label>
            <input
              type="checkbox"
              checked={!hiddenColumns.includes(column.selector)}
              onChange={() => handleColumnToggle(column.selector)}
            />
            {column.name}
          </label>
        </div>
      ))}
    </div>
  );

  return (
    <DataTable
      title="Test"
      data={data}
      selectableRows
      selectableRowsComponent={header}
      columns={columns.filter((column) => !hiddenColumns.includes(column.selector))} // Filtrujemy kolumny, aby ukryć te, które są na liście ukrytych kolumn
    />
  );
}

export default MyComponent;