import React from 'react';
import { Link, Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { useUser } from './context/UserContext';
import { Accordion } from 'react-bootstrap';
import Info from './Info';

const Administacja = () => {

  const { user } = useUser();
  const navigate = useNavigate();

  if (!user) return <Navigate to="/" />;
  if (!user.admin) return <Navigate to="/" />;

  return (
    <div className="d-flex" style={{ minHeight: '100vh', maxWidth: '100%' }}>
      <div style={{ width: '210px', flexShrink: 0, margin: 0 }}>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Administacja</Accordion.Header>
            <Accordion.Body>
              <Link to="info">
                Użytkownicy
              </Link>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header className="no-arrow" onClick={() => navigate('/konsola/info')}>Palne główny</Accordion.Header>
          </Accordion.Item>
        </Accordion>
      </div>
      <div style={{ flexGrow: 1, padding: '20px' }}>
        <Routes>
          <Route path="info" element={<Info />} />
        </Routes>
      </div>
    </div>
  );
};

export default Administacja;
