import React from 'react';
import DataTable from 'react-data-table-component';
import { Button } from 'react-bootstrap';

const paginationComponentOptions = {
	rowsPerPageText: 'Wyświetl na stronie',
	rangeSeparatorText: 'z',
	selectAllRowsItem: true,
	selectAllRowsItemText: 'wszystko',
};

function DataTableBase(props) {

	const handleGoBack = () => {
		window.history.back();
	};

	return (
		<>
			<DataTable
				dense
				pagination
				paginationComponentOptions={paginationComponentOptions}
				{...props} />

			{props.returnButton ? (
				<Button onClick={handleGoBack}>Powrót</Button>
			) : null}
		</>
	);
}

export default DataTableBase;