import React, { forwardRef } from 'react';
import { defaultSpaceBetweenQuestion, defaultSpaceLeftDependQuestion } from '../QuestionnaireCfg';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import pl from 'date-fns/locale/pl';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { DateTime } from 'luxon';
import moment from 'moment';
import 'moment/locale/pl';
moment.locale('pl');

const QuestionnaireRenderQuestionInputDateHour = forwardRef(({ index, question, formik, counter, prefix }, ref) => {

  const { t, i18n } = useTranslation();
  const fieldName = question.idField;

  const handleChangeForm = (event) => {
    let formikDate = '';
    if (event !== null) {
      formikDate = question.cfg.type === 'date' ?
        DateTime.fromJSDate(new Date(event)).toFormat('yyyy-MM-dd') :
        DateTime.fromJSDate(new Date(event)).toFormat('yyyy-MM-dd HH:mm');
    }
    formik.setFieldValue(fieldName, formikDate);
  }

  if (!question.show) return null;

  return (
    <div
      ref={ref}
      id={`mod-${question.idField}`}
      className={counter % 2 === 0 ? 'secondaryQuestion' : ''}
      style={{ paddingLeft: defaultSpaceLeftDependQuestion, paddingTop: defaultSpaceBetweenQuestion, paddingBottom: defaultSpaceBetweenQuestion }}
    >
      <div style={{
        paddingLeft: question.ifReqField && question.ifReqField.length > 0 ? defaultSpaceLeftDependQuestion : '0',
      }}>
        <label
          htmlFor={fieldName + 'i'}
          className={`mt-2 questionHead ${question.ifReqField ? 'questionDepended' : ''}`}
        >
          {!question.ifReqField && <span>{counter}. </span>}
          {question.question}
        </label>
        <div className='mt-2 questionOption'>
          {question.cfg.type === 'date' ?
            (
              <DatePicker
                selected={formik.values[fieldName]}
                onChange={handleChangeForm}
                dateFormat="yyyy-MM-dd"
                placeholderText={t('wybierz datę')}
                locale={i18n.language === 'pl' ? pl : null}
                name={fieldName}
                id={`question-${fieldName}`}
                className={`form-control ${formik.touched[fieldName] && formik.errors[fieldName] ? 'is-invalid' : ''}`}
              />
            ) : (
              <Datetime
                value={formik.values[fieldName]}
                onChange={handleChangeForm}
                dateFormat="YYYY-MM-DD"
                timeFormat="HH:mm"
                closeOnSelect={false}
                inputProps={{
                  placeholder: "Wybierz datę i godzinę",
                  readOnly: false,
                  id: `question-${fieldName}`,
                  className: `form-control ${formik.touched[fieldName] && formik.errors[fieldName] ? 'is-invalid' : ''}`
                }}
              />
            )
          }
          {formik.touched[fieldName] && formik.errors[fieldName] ? (
            <div className="error-message">{formik.errors[fieldName]}</div>
          ) : null}
        </div>
      </div>
    </div>
  );


});

export default QuestionnaireRenderQuestionInputDateHour;