import React, { useState, useEffect } from "react";

const SelectWrapperJson = ({ json = '', firstOption = '', fieldOptionText = '', fieldOptionValue = '', defaultSelected = '', dataSet = null }) => {
  const [optionList, setOptionList] = useState([]);

  useEffect(() => {

    if (json.length > 0) {

      fetch(json)
        .then(response => response.json())
        .then(data => {

          if ('data' in data && 'data' in data.data) {
            setOptionList(data.data);
          } else if ('data' in data) {
            setOptionList(data.data);
          } else {
            setOptionList(data);
          }
        })
        .catch(error => console.error('Błąd przy pobieraniu danych:', error));
    }

  }, [json]);

  useEffect(() => {
    if (dataSet !== null) setOptionList(dataSet);
  }, [dataSet]);

  return (
    <>
      {firstOption.length > 0 && <option value="">{firstOption}</option>}
      {optionList.map(option => (
        <option
          key={`${option[fieldOptionValue]}`}
          value={`${option[fieldOptionValue]}`}
          selected={option[fieldOptionValue].toString() === defaultSelected.toString()}
        >
          {`${option[fieldOptionText]}`}
        </option>
      ))}
    </>
  )
};

export default SelectWrapperJson;
