import appConfiguration from '../components/cfg/AppCfg';

const { apiUrl } = appConfiguration;

export const fetchUserDetails = async () => {
  try {
    const response = await fetch(`${apiUrl}/v1/user`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching user details:", error);
  }
};