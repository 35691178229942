import React, { useState, useEffect, useRef, createRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import QuestionnaireRenderQuestionInput from './questionnaireRenderQuestion/QuestionnaireRenderQuestionInput';
import QuestionnaireRenderQuestionTextarea from './questionnaireRenderQuestion/QuestionnaireRenderQuestionTextarea';
import QuestionnaireRenderQuestionRadio from './questionnaireRenderQuestion/QuestionnaireRenderQuestionRadio';
import QuestionnaireRenderQuestionCheckbox from './questionnaireRenderQuestion/QuestionnaireRenderQuestionCheckbox';
import QuestionnaireRenderQuestionInfoblok from './questionnaireRenderQuestion/QuestionnaireRenderQuestionInfoblok';
import QuestionnaireRenderQuestionInputFile from './questionnaireRenderQuestion/QuestionnaireRenderQuestionInputFile';
import QuestionnaireRenderQuestionSelect from './questionnaireRenderQuestion/QuestionnaireRenderQuestionSelect';
import QuestionnaireRenderQuestionInputDateHour from './questionnaireRenderQuestion/QuestionnaireRenderQuestionInputDateHour';
import QuestionnaireRenderQuestionInputPictures from './questionnaireRenderQuestion/QuestionnaireRenderQuestionInputPictures';
import { useTranslation } from 'react-i18next';
import FormModule from '../cfg/ConstantStrings';
import Dialog from '../common/Dialog';

export const QuestionnaireRenderQuestionOnly = ({ questions, formik, counter, prefix = '', addSchemaCfg, setQuestions, setShowDialog, setDialogProps, refs }) => {

  return (
    <>
      {questions.map((question, index) => {
        const key = `question_${counter}_${index}`;
        let ComponentToRender;

        switch (question.type) {
          case FormModule.INPUT:
            ComponentToRender = QuestionnaireRenderQuestionInput;
            break;
          case FormModule.TEXTAREA:
            ComponentToRender = QuestionnaireRenderQuestionTextarea;
            break;
          case FormModule.RADIO:
            ComponentToRender = QuestionnaireRenderQuestionRadio;
            break;
          case FormModule.CHECKBOX:
            ComponentToRender = QuestionnaireRenderQuestionCheckbox;
            break;
          case FormModule.INFOBLOCK:
            ComponentToRender = QuestionnaireRenderQuestionInfoblok;
            break;
          case FormModule.INPUT_FILE:
            ComponentToRender = QuestionnaireRenderQuestionInputFile;
            break;
          case FormModule.SELECT:
            ComponentToRender = QuestionnaireRenderQuestionSelect;
            break;
          case FormModule.DATEHOUR:
            ComponentToRender = QuestionnaireRenderQuestionInputDateHour;
            break;
          case FormModule.PICTURES:
            ComponentToRender = QuestionnaireRenderQuestionInputPictures;
            break;
          default:
            ComponentToRender = () => <h1>BRAK dla -{'>'} {question.type} </h1>;
        }

        if (ComponentToRender) {
          if (!question.ifReqField) counter++;
          return (
            <ComponentToRender
              key={key}
              index={index}
              question={question}
              questions={questions}
              formik={formik}
              counter={counter}
              prefix={prefix}
              addSchemaCfg={addSchemaCfg}
              setQuestions={setQuestions}
              setShowDialog={setShowDialog}
              setDialogProps={setDialogProps}
              ref={refs.current[index]}
            />
          );
        }
      })}
    </>
  );
}

export const GenerateFormikSchema = (question) => {

  let schema;
  const { t } = useTranslation();

  if (question.show) {

    switch (question.type) {
      case FormModule.INPUT:
        schema = Yup.string();
        if (question.cfg.minLength > 0) schema = schema.min(question.cfg.minLength, t('Zbyt krótkie (min.') + question.cfg.minLength + ')');
        if (question.cfg.maxLength > 0) schema = schema.max(question.cfg.maxLength, t('Zbyt długie (max.') + question.cfg.maxLength + ')');
        if (question.cfg.minLength > 0) schema = schema.required(t('To pole jest wymagane'));
        if (question.cfg.validation === 'email') schema = schema.email(t('Niepoprawny adres email'));
        if (question.cfg.validation === 'phone') schema = schema.matches(/^[0-9()+\-\s]{9,}$/, t('Numer telefonu jest nieprawidłowy'));
        if (question.cfg.validation === 'date') schema = schema.matches(/^(19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/, t('Data musi być w formacie RRRR-MM-DD'));
        if (question.cfg.validation === 'price') schema = schema.matches(/^\d+(\.\d{1,2})?$/, t('Wpisz cenę w formialceie 123.12'));
        if (question.cfg.validation === 'quantity') schema = schema.matches(/^\d+$/, t('Wpisz liczbę całkowitą'));
        break;
      case FormModule.TEXTAREA:
        schema = Yup.string();
        if (question.cfg.minLength > 0) schema = schema.min(question.cfg.minLength, t('Zbyt krótkie (min.') + question.cfg.minLength + ')');
        if (question.cfg.maxLength > 0) schema = schema.max(question.cfg.maxLength, t('Zbyt długie (max.') + question.cfg.maxLength + ')');
        if (question.cfg.minLength > 0) schema = schema.required(t('To pole jest wymagane'));
        break;
      case FormModule.RADIO:
        schema = Yup.string();
        schema = schema.required(t('Wybierz jedną z opcji.'));
        break;
      case FormModule.CHECKBOX:
        schema = Yup.array();
        if (question.cfg.minChecked > 0) schema = schema.min(question.cfg.minChecked, t('Wybierz przynajmniej') + ' ' + question.cfg.minChecked + ' ' + t('opcje(-i)'));
        if (question.cfg.maxChecked > 0) schema = schema.max(question.cfg.maxChecked, t('Wybierz najwięcej') + ' ' + question.cfg.maxChecked + ' ' + t('opcje(-i)'));
        break;
      case FormModule.INFOBLOCK:
        schema = Yup.string();
        break
      case FormModule.INPUT_FILE:
        schema = Yup.array().of(
          Yup.mixed()
        ).test('filesRequired', t('Wybierz plik lub pliki'), value => value && value.length > 0);
        break;
      case FormModule.SELECT:
        schema = Yup.array();
        if (question.cfg.minChecked > 0) schema = schema.min(question.cfg.minChecked, t('Wybierz przynajmniej') + ' ' + question.cfg.minChecked + ' ' + t('opcje(-i)'));
        if (question.cfg.maxChecked > 0) schema = schema.max(question.cfg.maxChecked, t('Wybierz najwięcej') + ' ' + question.cfg.maxChecked + ' ' + t('opcje(-i)'));
        break;
      case FormModule.DATEHOUR:
        schema = Yup.string();
        schema = schema.matches(/^(19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$|^(19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])\s([01]\d|2[0-3]):([0-5]\d)$/, t('Data musi być w formacie np. 2024-12-24 lub 2024-12-24 16:45'));
        schema = schema.required(t('To pole jest wymagane.'));
        break;
      case FormModule.PICTURES:
        schema = Yup.string().test(
          "CountOfRequired",
          "Wymagane jest wgranie min " + question.cfg.minQuantity + " zdjęć.",
          function (item) {
            let count = 0;
            for (let i = 0; i < question.cfg.maxQuantity; i++) {
              if (this.parent[question.idField + '_' + i] !== undefined) {
                count++;
              }
            }
            return count >= question.cfg.minQuantity
          }
        )
        break;
    }
  }
  return schema;
}

export const generateFormikConfiguration = ({ questions, prefix = '' }) => {

  const validationSchema = {};
  const initialValues = {};

  let fieldName;

  questions.forEach((question, index) => {

    fieldName = question.idField;

    if ([FormModule.SELECT, FormModule.CHECKBOX, FormModule.INPUT_FILE].includes(question.type)) {
      initialValues[fieldName] = [];
      validationSchema[fieldName] = GenerateFormikSchema(question);
    } else if ([FormModule.INFOBLOCK].includes(question.type)) {
    } else if ([FormModule.PICTURES].includes(question.type)) {
      for (let i = 0; i < question.cfg.maxQuantity; i++) {
        const fieldNameIndex = `${fieldName}_${i}`;
        initialValues[fieldNameIndex] = '';
        validationSchema[fieldNameIndex] = GenerateFormikSchema(question);
      }
    } else {
      initialValues[fieldName] = '';
      validationSchema[fieldName] = GenerateFormikSchema(question);
    }
  });

  return { initialValues: initialValues, validationSchema: validationSchema };
}

const QuestionnaireRenderOpenDependentQuestion = (formik, questions, setQuestions) => {
  for (const key in formik.values) {
    if (formik.values[key] && formik.values[key].length !== 0) {
      const questionIndex = questions.findIndex(question => question.idField === key);
      if (questionIndex !== -1) {
        const updatedQuestions = [...questions];
        updatedQuestions[questionIndex].show = true;
        setQuestions(updatedQuestions);
      }

      questions.forEach((question, index) => {
        if (!question.show && question.ifReqValue && question.ifReqField) {
          const key = question.ifReqField;
          const value = formik.values[key];

          if (value && question.ifReqValue.includes(value)) {
            const updatedQuestions = [...questions];
            updatedQuestions[index].show = true;
            setQuestions(updatedQuestions);
          }

          if (Array.isArray(value) && value.some(item => question.ifReqValue.includes(item))) {
            const updatedQuestions = [...questions];
            updatedQuestions[index].show = true;
            setQuestions(updatedQuestions);
          }

        }
      });
    }
  }
}

const QuestionnaireRender = ({ questionnaire, questions, setQuestions, formsButtons }) => {

  const { t } = useTranslation();
  const { initialValues: ini, validationSchema: val } = generateFormikConfiguration({ questions: questions, prefix: '' });
  const [validationSchema, setValidationSchema] = useState(Yup.object(val));
  const [initialValues, setInitialValues] = useState(ini);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [buttonLoad, setButtonLoad] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogProps, setDialogProps] = useState({ title: '', contentTxt: '', buttons: [], dialogContentComponent: null, dialogSize: 50, showDialog: false });

  const refs = useRef(questions.map(() => React.createRef()));

  useEffect(() => {
    refs.current = refs.current.slice(0, questions.length);
    refs.current = questions.map((_, index) => refs.current[index] || createRef());
  }, [questions]);

  useEffect(() => {
    setValidationSchema(Yup.object(val));
    checkIfRecordExists();
  }, [JSON.stringify(val)]);

  useEffect(() => {
    if (dataLoaded) {
      QuestionnaireRenderOpenDependentQuestion(formik, questions, setQuestions);
    }
  }, [dataLoaded]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: (values) => {
      const formData = new FormData();
      for (const key in values) {
        const fieldValue = values[key];
        if (Array.isArray(fieldValue)) {
          fieldValue.forEach((file) => {
            formData.append(key, file);
          });
        } else {
          const imageType = typeBase64Image(fieldValue);
          if (imageType) {
            const file = base64ToFile(fieldValue, `key_image.${imageType}`);
            formData.append(key, file);
          } else {
            formData.append(key, fieldValue);
          }
        }
      }
      console.table(formData);
    }
  });

  const typeBase64Image = (str) => {
    const base64Pattern = /^data:image\/(jpeg|png|gif);base64,/;
    const match = str.match(base64Pattern);
    if (match) {
      return match[1];
    }
    return false;
  };

  const isBase64 = (str) => {
    const base64Pattern = /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;
    return base64Pattern.test(str);
  };

  const base64ToFile = (base64String, filename) => {
    const arr = base64String.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const loadDataIntoTheForm = () => {
    const request = indexedDB.open("indexedDBlocal", 1);

    request.onerror = function (event) {
      console.error('Błąd podczas otwierania bazy danych', event.target.errorCode);
    };

    request.onsuccess = function (event) {
      const db = event.target.result;

      const transaction = db.transaction(['questionnaireRecord'], 'readonly');
      const store = transaction.objectStore('questionnaireRecord');

      const getRequest = store.get(questionnaire.id);

      getRequest.onsuccess = function (event) {
        const record = event.target.result;

        if (record) {
          formik.setValues(record.formikValues);
          setDataLoaded(true);
        } else {
          alert('Brak danych dla identyfikatora 1 w bazie danych');
        }
      };

      getRequest.onerror = function (event) {
        console.error('Błąd podczas odczytu danych', event.target.errorCode);
      };
    };

    request.onupgradeneeded = function (event) {
      const db = event.target.result;
      db.createObjectStore('questionnaireRecord', { keyPath: 'id' });
    };
  }

  const checkIfRecordExists = async () => {
    try {
      const db = await new Promise((resolve, reject) => {
        const request = indexedDB.open("indexedDBlocal", 1);

        request.onerror = function (event) {
          reject(false);
        };

        request.onsuccess = function (event) {
          resolve(event.target.result);
        };

        request.onupgradeneeded = function (event) {
          const db = event.target.result;
          db.createObjectStore('questionnaireRecord', { keyPath: 'id' });
          resolve(db);
        };
      });

      const transaction = db.transaction(['questionnaireRecord'], 'readonly');
      const store = transaction.objectStore('questionnaireRecord');
      const getRequest = store.get(questionnaire.id);

      getRequest.onsuccess = function (event) {
        const record = event.target.result;

        if (record) {
          setButtonLoad(true);
        } else {
          setButtonLoad(false);
        }
      };

      getRequest.onerror = function (event) {
        setButtonLoad(false);
      };

    } catch (error) {
      setButtonLoad(false);
    }
  }

  const saveDataFromTheForm = () => {

    const request = indexedDB.open("indexedDBlocal", 1);

    request.onerror = function (event) {
      console.error('Błąd podczas otwierania bazy danych', event.target.errorCode);
    };

    request.onsuccess = function (event) {
      const db = event.target.result;

      const transaction = db.transaction(['questionnaireRecord'], 'readwrite');
      const store = transaction.objectStore('questionnaireRecord');

      const data = { id: questionnaire.id, formikValues: formik.values };
      const getRequest = store.get(questionnaire.id);

      getRequest.onsuccess = function (event) {
        const existingRecord = event.target.result;

        if (existingRecord) {
          const updateRequest = store.put(data);
          updateRequest.onerror = function (event) {
            console.error('Błąd podczas aktualizacji danych', event.target.errorCode);
          };
          updateRequest.onsuccess = function (event) {
            setButtonLoad(true);
          };
        } else {
          const addRequest = store.add(data);
          addRequest.onerror = function (event) {
            console.error('Błąd podczas dodawania danych', event.target.errorCode);
          };
          addRequest.onsuccess = function (event) {
            setButtonLoad(true);
          };
        }
      };

    };

    request.onupgradeneeded = function (event) {
      const db = event.target.result;
      const store = db.createObjectStore('questionnaireRecord', { keyPath: 'id' });
    };

  }

  const renderForm = () => {
    let counter = 0

    return (
      <form>
        {questionnaire.name.length > 0 && (
          <><hr className="patternH3" /><h2>{questionnaire.name}</h2><hr className="patternH3" /></>
        )}
        {questionnaire.description.length > 0 && (
          <><div>{questionnaire.description}</div><hr className="pattern" /></>
        )}
        <ul>
          <QuestionnaireRenderQuestionOnly
            questions={questions}
            setQuestions={setQuestions}
            formik={formik}
            counter={counter}
            setShowDialog={setShowDialog}
            setDialogProps={setDialogProps}
            refs={refs}
          />
        </ul>

        {formsButtons ? (
          <button className="btn btn-outline-secondary form-control" type='button' onClick={() => handleSubmit(true)}>{t('wyślij')}</button>
        ) : (
          <button className="btn btn-outline-secondary form-control" type='button' onClick={() => handleSubmit(false)}>{t('test walidacji formularza')}</button>
        )}
        <button className="btn btn-outline-secondary form-control" type='button' onClick={() => saveDataFromTheForm()}>{t('zapisz dane tymczasowe')}</button>
        {buttonLoad && (<button className="btn btn-outline-secondary form-control" type='button' onClick={() => loadDataIntoTheForm()}>{t('wczytaj dane do formularza')}</button>)}
        <Dialog
          title={dialogProps.title}
          contentTxt={dialogProps.contentTxt}
          show={showDialog}
          buttons={[
            { variant: 'secondary', text: 'Close', onClick: () => { setShowDialog(false); } }
          ]}
          dialogSize={50}
          dialogClassName="unique-modal-QuestionnaireRender"
        />
      </form>
    );
  };

  const handleSubmit = async (doSubmit) => {
    const formikErrorTab = await formik.validateForm(formik.values);
    if (doSubmit) {
      formik.handleSubmit();
    } else {
      formik.setErrors(formikErrorTab);
      const touchedFields = Object.keys(formik.values).reduce((acc, key) => {
        acc[key] = true;
        return acc;
      }, {});
      formik.setTouched(touchedFields);
    }
    let firstKeyMod = Object.keys(formikErrorTab)[0];
    if (firstKeyMod) {
      if (firstKeyMod.includes("_0")) {
        firstKeyMod = firstKeyMod.replace("_0", "");
      }
      let foundIndex = -1;
      for (let i = 0; i < refs.current.length; i++) {
        if (refs.current[i].current && refs.current[i].current.attributes) {
          if (Array.from(refs.current[i].current.attributes).some(
            attribute => attribute.name === "id" &&
              attribute.value === "mod-" + firstKeyMod)
          ) {
            foundIndex = i;
            break;
          }
        }
      }
      if (foundIndex !== -1) {
        refs.current[foundIndex].current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  return renderForm();

};

export default QuestionnaireRender;