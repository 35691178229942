import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';

const MapComponent = ({ text }) => <div>{text}</div>;

const GeolocationComponent = () => {
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Sprawdzanie czy przeglądarka obsługuje Geolocation API
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          setError(error.message);
        }
      );
    } else {
      setError('Twoja przeglądarka nie obsługuje Geolocation API.');
    }
  }, []);

  const defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33
    },
    zoom: 11
  };

  return (
    <div>
      {error ? (
        <p>Błąd: {error}</p>
      ) : (
        <p>
          Twoje położenie geograficzne: {latitude}, {longitude}
        </p>
      )}
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyCTd1KQcQoiWDIhvY-6p7x1cWKgRvaoqtc' }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <MapComponent
          lat={defaultProps.center.lat}
          lng={defaultProps.center.lng}
          text="My Marker"
        />
      </GoogleMapReact>
    </div>
  );
};

export default GeolocationComponent;
