import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import QuestionnaireTypeQuestionDependentQuestions from './QuestionnaireTypeQuestionDependentQuestions';
import { defaultTextareaMaxLength } from '../QuestionnaireCfg';

const QuestionnaireTypeQuestionTextarea = ({ index, questions, setQuestions }) => {

    const [minLength, setMinLenght] = useState(questions[index]?.cfg?.minLength || 0);
    const [maxLength, setMaxLenght] = useState(questions[index]?.cfg?.maxLength || 0);

    const { t } = useTranslation();

    const handleCfgChange = (event, field) => {
        const updatedQuestions = [...questions];
        updatedQuestions[index]['cfg'][field] = parseInt(event.target.value, 10);
        setQuestions(updatedQuestions);
        if (field === 'minLength') setMinLenght(parseInt(event.target.value, 10));
        if (field === 'maxLength') setMaxLenght(parseInt(event.target.value, 10));
    };

    useEffect(() => {
        if (parseInt(minLength, 10) > parseInt(maxLength, 10)) {
            setMaxLenght(minLength);
            const updatedQuestions = [...questions];
            updatedQuestions[index]['cfg'].maxLength = minLength;
            setQuestions(updatedQuestions);
        }
    }, [minLength, maxLength]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                <div style={{ width: '150px' }}>{t('min długość')}</div>
                <input
                    type="number"
                    min={0}
                    max={defaultTextareaMaxLength}
                    value={minLength}
                    onChange={(event) => handleCfgChange(event, 'minLength')}
                    className="form-control"
                />
            </div>
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                <div style={{ width: '150px' }}>{t('max długość')}</div>
                <input
                    type="number"
                    min={0}
                    max={defaultTextareaMaxLength}
                    value={maxLength}
                    onChange={(event) => handleCfgChange(event, 'maxLength')}
                    className="form-control"
                />
            </div>
            <QuestionnaireTypeQuestionDependentQuestions index={index} questions={questions} setQuestions={setQuestions} />
        </div>
    );
};

export default QuestionnaireTypeQuestionTextarea;