import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const Spinner = ({ spinnerInfo }) => {
  const { t } = useTranslation();
  const spinnerShowTxt = spinnerInfo || t('Ładowanie danych');

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1050,
      backgroundColor: 'rgba(255, 255, 255, 0.8)'
    }}>
      <Container className="text-center">
        <Row>
          <Col xs="12">
            <strong>{spinnerShowTxt}</strong>
          </Col>
          <Col xs="12" className="mt-3">
            <div className="spinner-border text-primary" style={{ width: '8rem', height: '8rem' }} role="status">
              <span className="visually-hidden"></span>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Spinner;
