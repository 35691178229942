import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import appConfiguration from '../cfg/AppCfg';
import { useUser } from '../context/UserContext';
import Spinner from '../common/Spinner';
import { useTranslation } from 'react-i18next';

const LoginForm = () => {
  const { t } = useTranslation();
  const { onLogin, user } = useUser();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState(appConfiguration.defaultPassword);
  const [error, setError] = useState('');
  const [response, setResponse] = useState('');
  const [switchForm, setSwitchForm] = useState('logowanie');
  const [showSpinner, setShowSpinner] = useState(false);
  const navigate = useNavigate();

  // TODO ASM-20 Rewrite to Firebase
  const handleFormReset = async () => {
    try {
      const response = await fetch(appConfiguration.resetLinkPost, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username })
      });

      if (!response.ok) {
        throw new Error('Błak połączenia z serwerem.');
      }

      const responseData = await response.json();
      const success = responseData.success;

      if (success) {
        setSwitchForm('logowanie');
        setError('');
        setResponse(t('Sprawdź swoją skrzynkę pocztową.'));
      } else {
        setError(t('Twoje konto nie zostało rozpoznane.'));
      }

    } catch (error) {
      setError(error.message);
    }

  };

  const handleFormLogin = () => {
    setError('');
    if (username.length == 0 || password.length == 0) {
      setError(t('Brak właściwych danych do logowania.'));
      return;
    }
    setShowSpinner(true);
    onLogin(username, password)
      .then(() => {
        navigate(appConfiguration.defaultPathAfterLogin);
      })
      .catch((error) => {
        setShowSpinner(false);
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log('Firebase login error', errorCode, errorMessage);
        setError(t('Niepoprawne logowanie'));
      })
  };

  const switchFormAction = () => {
    const form = (switchForm === 'logowanie') ? 'reset' : 'logowanie';
    setSwitchForm(form);
    setError('');
    setResponse('');
  };

  if (user) {
    return <Navigate to={appConfiguration.defaultPathAfterLogin} />
  }

  return (
    <Container>
      {
        (switchForm === 'reset') ? (
          <>
            <h2>{t('Resetowanie hasła')}</h2>
            <Form>
              <Form.Group controlId="formUsername">
                <Form.Label>{t('Adres e-mail')}:</Form.Label>
                <Form.Control type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
              </Form.Group>
              <Form.Group controlId="formButton" className="d-flex justify-content-between">
                <Button variant="primary" type="button" onClick={handleFormReset} className="mt-2">
                  {t('Resetowanie hasła')}
                </Button>
                <Button variant="primary" type="button" onClick={switchFormAction} className="mt-2">
                  {t('Formularz logowania')}
                </Button>
              </Form.Group>
            </Form>
          </>
        ) : (
          <>
            <h2>{t('Formularz logowania')}</h2>
            < Form >
              <Form.Group controlId="formUsername">
                <Form.Label>{t('Użytkownik')}:</Form.Label>
                <Form.Control type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
              </Form.Group>
              <Form.Group controlId="formHaslo">
                <Form.Label>{t('Hasło')}:</Form.Label>
                <Form.Control type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
              </Form.Group>
              <Form.Group controlId="formButton" className="d-flex justify-content-between">
                <Button variant="primary" type="button" onClick={handleFormLogin} className="mt-2">
                  {t('Zaloguj')}
                </Button>
                <Button variant="primary" type="button" onClick={switchFormAction} className="mt-2">
                  {t('Resetowanie hasła')}
                </Button>
              </Form.Group>
            </Form>
            {showSpinner && <Spinner marginTop="2cm" spinnerInfo={t("Logowanie do systemu")} />}
          </>
        )
      }
      <br />
      {error && <Alert variant="danger">{error}</Alert>}
      {response && <Alert variant="success">{response}</Alert>}
    </Container>
  );
};

export default LoginForm;
