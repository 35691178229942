import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SelectWrapperJson from '../../common/SelectWrapperJson';
import DictionaryContext from '../../context/DictionaryContext';
import Dialog from '../../common/Dialog';
import Spinner from '../../common/Spinner';
import QuestionnaireRender from '../QuestionnaireRender';
import { fetchQuestionnaireTemplate } from '../../../services/questionnaire';

const QuestionnaireTypeQuestionCrossProducts = ({ index, questions, setQuestions }) => {

  const { t } = useTranslation();

  const { dictionaryProductsList, dictionaryTemplates } = useContext(DictionaryContext);

  const [dictionaryTemplatesFiltered, setDictionaryTemplatesFiltered] = useState(dictionaryTemplates);
  const [dictionaryProductsListFiltered, setDictionaryProductsListFiltered] = useState(dictionaryProductsList);

  const [dialogProps, setDialogProps] = useState({ title: '', contentTxt: '', buttons: [], dialogContentComponent: null, dialogSize: 50 });
  const [showDialog, setShowDialog] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const [templateId, setTemplateId] = useState(questions[index]?.cfg?.templateId || '');
  const [templateButtonPrev, setTemplateButtonPrev] = useState(true);

  useEffect(() => {
    setTemplateButtonPrev(templateId === '');
  }, [templateId]);

  const handleCfgChange = (event, field) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index]['cfg'][field] = event.target.value;
    setQuestions(updatedQuestions);
    if (field === 'templateId') setTemplateId(event.target.value);
  }

  const handleFilterProductsListChange = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    const selectedIdProductList = questions[index]?.cfg?.idProductList;

    const selectedProductsList = dictionaryProductsList.find(
      row => row.idProductsList == selectedIdProductList
    );

    const filteredProductsList = dictionaryProductsList.filter(
      row => row.listName.toLowerCase().includes(searchTerm)
    );

    if (selectedProductsList && !filteredProductsList.some(
      row => row.idProductsList == selectedIdProductList)
    ) {
      setDictionaryProductsListFiltered([selectedProductsList, ...filteredProductsList]);
    } else {
      setDictionaryProductsListFiltered(filteredProductsList);
    }
  };

  const handleFilterTemplatesChange = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    const selectedTemplateId = questions[index]?.cfg?.templateId;
    const selectedTemplate = dictionaryTemplates.find(
      row => row.id === selectedTemplateId
    );
    const filteredTemplates = dictionaryTemplates.filter(row =>
    (row.name.toLowerCase().includes(searchTerm) ||
      row.description.toLowerCase().includes(searchTerm))
    );
    if (selectedTemplate && !filteredTemplates.some(
      row => row.id === selectedTemplateId)
    ) {
      setDictionaryTemplatesFiltered([selectedTemplate, ...filteredTemplates]);
    } else {
      setDictionaryTemplatesFiltered(filteredTemplates);
    }
  };

  const handleClose = () => setShowDialog(false);

  const handleViewTemplate = async () => {

    setShowSpinner(true);

    const data = await fetchQuestionnaireTemplate(templateId);

    setShowSpinner(false);

    setDialogProps({
      title: t('Podgląd szablonu'),
      dialogSize: '98',
      buttons: [
        { text: t('zamknij'), variant: 'secondary', onClick: handleClose }
      ],
      dialogContentComponent: () => (
        <QuestionnaireRender
          questionnaire={{ id: 0, name: '', description: '' }}
          questions={data.questionsCfg}
        />
      )
    });
    setShowDialog(true);
  }

  return (
    <>
      <Dialog
        title={dialogProps.title}
        contentTxt={dialogProps.contentTxt}
        show={showDialog}
        buttons={dialogProps.buttons}
        dialogContentComponent={dialogProps.dialogContentComponent}
        dialogSize={dialogProps.dialogSize}
        dialogClassName="unique-modal-QuestionnaireTypeQuestionCrossProducts"
      />
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          <div style={{ width: '130px' }}>{t('lista produktów')}</div>
          <select
            onChange={(event) => handleCfgChange(event, 'idProductList')}
            className="form-control"
            size="5"
          >
            <SelectWrapperJson
              dataSet={dictionaryProductsListFiltered}
              fieldOptionText='listName'
              fieldOptionValue='idProductsList'
              defaultSelected={questions[index]?.cfg?.idProductList || ''}
            />
          </select>
        </div>
        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          <div style={{ width: '130px' }}>{t('szukaj')}</div>
          <input
            type="text"
            className="form-control"
            onChange={handleFilterProductsListChange}
          />
        </div>
        <hr />
        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          <div style={{ width: '130px' }}>{t('szablon ankiety')}</div>
          <select
            onChange={(event) => handleCfgChange(event, 'templateId')}
            className="form-control"
            size="5"
          >
            <SelectWrapperJson
              dataSet={dictionaryTemplatesFiltered}
              fieldOptionText='name'
              fieldOptionValue='id'
              defaultSelected={questions[index]?.cfg?.templateId || ''}
            />
          </select>
        </div>
        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          <div style={{ width: '145px' }}>{t('szukaj')}</div>
          <input
            type="text"
            className="form-control"
            onChange={handleFilterTemplatesChange}
          />
          <input
            type="button"
            disabled={templateButtonPrev}
            onClick={handleViewTemplate}
            value={t("podgląd")}
          />
        </div>
      </div>
      {showSpinner && (<Spinner />)}
    </>
  );
};

export default QuestionnaireTypeQuestionCrossProducts;
