import React, { useState, useEffect, useContext } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import 'bootstrap-icons/font/bootstrap-icons.css';
import {
  createQuestionnaire,
  fetchQuestionnaire,
  fetchQuestionnaireTemplate,
  updateQuestionnaire,
} from '../../services/questionnaire';
import Dialog from '../common/Dialog';
import Toast from '../common/Toast';
import QuestionnaireTypeQuestion from './QuestionnaireTypeQuestion';
import QuestionnaireTypeQuestionConfiguration from './QuestionnaireTypeQuestionConfiguration';
import QuestionnaireRender from './QuestionnaireRender';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DictionaryContext from '../context/DictionaryContext';
import UserContext from '../context/UserContext';
import FormModule from '../cfg/ConstantStrings';
import Spinner from '../common/Spinner';
import { defaultQuestionnaireNameLength, defaultQuestionnaireDescriptionLength } from './QuestionnaireCfg';
import { Row, Col, FormControl, Form, Button } from 'react-bootstrap';

const DynamicForm = ({ type }) => {

  const { t } = useTranslation();
  const { id } = useParams();
  const { describeDictionaryValue } = useContext(DictionaryContext);
  const { user } = useContext(UserContext);
  const defaultQuestionCfg = {
    question: '', type: FormModule.INPUT,
    cfg: {
      validation: 'empty',
      minLength: 0,
      maxLength: 0
    },
    show: true,
    idField: 1
  };
  const defaultQuestionnaire = { id: 0, name: '', description: '', archive: false };
  const [questionnaire, setQuestionnaire] = useState(defaultQuestionnaire);

  const [questions, setQuestions] = useState([]);
  const [questionsTransform, setQuestionsTransform] = useState([]);

  const [validationSchema, setValidationSchema] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const [setFormikFieldValue, setSetFormikFieldValue] = useState(true);

  const [showDialog, setShowDialog] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [dialogProps, setDialogProps] = useState({
    title: '',
    contentTxt: '',
    buttons: [],
    dialogContentComponent: null,
    dialogSize: 50
  });

  const [showToast, setShowToast] = useState(false);
  const toastClose = () => { setShowToast(false); }
  const [toastProps, setToastProps] = useState({
    message: '',
    title: '',
    delay: 5000,
    position: 'middle-center',
    toastTitleSmall: '',
    hideToast: toastClose
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    validateOnChange: true,
  });

  const generateFormikConfigurationEditor = ({ questions }) => {

    const validationSchema = {};
    const initialValues = {};
    let schema, fieldName;

    schema = Yup.string();
    schema = schema.required(t('To pole jest wymagane.'))
    initialValues['name'] = '';
    validationSchema['name'] = schema;
    initialValues['description'] = '';
    validationSchema['description'] = schema;

    questions.forEach((question, index) => {
      schema = Yup.string();
      if (![FormModule.BLOCKTEMPLATE, FormModule.CROSSPROD].includes(question.type)) {
        fieldName = `question-${question.idField}`;
        schema = schema.required(t('To pole jest wymagane.'));
        initialValues[fieldName] = '';
        validationSchema[fieldName] = schema;
      } else if (question.type === FormModule.BLOCKTEMPLATE) {
        fieldName = `question-${question.idField}-templateId`;
        schema = schema.required(t('Proszę wskazać szablon.'));
        initialValues[fieldName] = '';
        validationSchema[fieldName] = schema;
      }
    });

    return { initialValues: initialValues, validationSchema: validationSchema };
  }

  const runFormikConfigurationEditor = (questions, updateInitialValues, updateValidationSchema, updateFormikFieldValue) => {
    console.log("runFormikConfigurationEditor");
    console.log(JSON.stringify(questions));
    const { initialValues, validationSchema } = generateFormikConfigurationEditor({ questions: questions });
    if (updateInitialValues) setInitialValues(initialValues);
    if (updateValidationSchema) setValidationSchema(Yup.object(validationSchema));
    if (updateFormikFieldValue) setSetFormikFieldValue(!setFormikFieldValue);
  }

  useEffect(() => {
    const fetchData = async () => {
      setShowSpinner(true);
      let dataJSON;
      if (id != 0) {
        const fetchData = type === "questionnaire" ? fetchQuestionnaire : fetchQuestionnaireTemplate;
        dataJSON = await fetchData(id);
        setQuestionnaire(dataJSON);
      } else {
        setQuestionnaire(defaultQuestionnaire);
      }

      const dataQuestion = id != 0 ? dataJSON.questionsCfg : [defaultQuestionCfg];
      setQuestions(dataQuestion);
      
      runFormikConfigurationEditor(dataQuestion, true, true, true);

      setShowMenu(true);
      setShowSpinner(false);
    }
    fetchData();
  }, [id, type]);

  useEffect(() => {
    if (questions.length > 0) {
      formik.setFieldValue('name', questionnaire.name);
      formik.setFieldValue('description', questionnaire.description);
      questions.forEach(question => {
        formik.setFieldValue(`question-${question.idField}`, question.question);
      });
    }
  }, [setFormikFieldValue]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let transformedQuestions = questions;
        while (transformedQuestions.some(question => (
          (question.type === FormModule.CROSSPROD && question.cfg.templateId && question.cfg.idProductList > 0) ||
          (question.type === FormModule.BLOCKTEMPLATE && question.cfg.templateId)
        ))) {
          transformedQuestions = await processQuestions(transformedQuestions);
        }
        setQuestionsTransform(transformedQuestions);

        runFormikConfigurationEditor(questions, false, true, false);
      } catch (error) {
        console.error('Błąd podczas przetwarzania danych:', error);
      }
    };
    fetchData();
  }, [questions]);

  useEffect(() => {
    if (questionsTransform.length > 0) {
      //const { initialValues, validationSchema } = generateFormikConfigurationEditor({ questions: questions });
      //setValidationSchema(Yup.object(validationSchema));
      runFormikConfigurationEditor(questions, false, true);
    }
  }, [questionsTransform]);

  const handleCloseDialog = () => setShowDialog(false);

  const handleCopyToClipboard = (str) => {
    navigator.clipboard.writeText(str)
  }

  const processQuestions = async (questions) => {
    const newQuestions = [];
    let idTransform = {};

    for (const question of questions) {
      const updatedQuestion = { ...question };

      if (question.type === FormModule.CROSSPROD && question.cfg.templateId && question.cfg.idProductList > 0) {
        try {
          const template = await fetchQuestionnaireTemplate(question.cfg.templateId)

          const responseProductCfg = await fetch(`/json/listaProduktow_${question.cfg.idProductList}.json`);
          const dataProductCfg = await responseProductCfg.json();

          for (let i = 0; i < dataProductCfg.idProduct.length; i++) {
            for (let j = 0; j < template.questionsCfg.length; j++) {
              const dataQuestion = { ...template.questionsCfg[j] };
              dataQuestion.question = dataQuestion.question.replace('%PRODUKT%', describeDictionaryValue('Produkt', dataProductCfg.idProduct[i]));
              let maxIdField = findMaxIdFieldValue(newQuestions);
              maxIdField++;
              let newIdField = maxIdField;
              idTransform[dataQuestion.idField] = newIdField;
              dataQuestion.prodId = dataProductCfg.idProduct[i];
              dataQuestion.idField = newIdField;
              if (dataQuestion.ifReqField !== undefined && idTransform[dataQuestion.ifReqField] !== undefined) {
                dataQuestion.ifReqField = idTransform[dataQuestion.ifReqField];
              }
              newQuestions.push(dataQuestion);
            }
          }
        } catch (error) {
          console.error('Błąd podczas odbierania danych:', error);
        }
      } else if (question.type === FormModule.BLOCKTEMPLATE && question.cfg.templateId) {
        try {
          const template = await fetchQuestionnaireTemplate(question.cfg.templateId)
          for (let j = 0; j < template.questionsCfg.length; j++) {
            const newQuestion = { ...template.questionsCfg[j] };
            let maxIdField = findMaxIdFieldValue(newQuestions);
            maxIdField++;
            let newIdField = maxIdField;
            idTransform[newQuestion.idField] = newIdField;
            newQuestion.idField = newIdField;
            if (newQuestion.ifReqField !== undefined && idTransform[newQuestion.ifReqField] !== undefined) {
              newQuestion.ifReqField = idTransform[newQuestion.ifReqField];
            }
            newQuestions.push(newQuestion);
          }
        } catch (error) {
          console.error('Błąd podczas odbierania danych:', error);
        }
      } else {
        newQuestions.push(updatedQuestion);
      }
    }

    return newQuestions;
  };

  const handlePreview = () => {
    const updateQuestions = [...questions];
    setQuestions(updateQuestions);

    setDialogProps({
      title: '',
      dialogSize: '98',
      buttons: [
        { text: t('zamknij'), variant: 'secondary', onClick: handleCloseDialog }
      ],
      dialogContentComponent: () => (
        <QuestionnaireRender
          questionnaire={questionnaire}
          questions={questionsTransform}
          setQuestions={setQuestionsTransform}
        />
      )
    });
    setShowDialog(true);
  }

  const TransformQuestions = () => {
    setQuestions(questionsTransform);
    handleCloseDialog();
  }

  const handleTransformQuestions = () => {
    setDialogProps({
      title: t("Czy chcesz kontynuować?"),
      dialogSize: '80',
      contentTxt: 'Ten proces przekształca obecny wygląd szablonu ankiety ("bloki szablonów" i "produkty i szablony") docelowy (podstawowy). Dzięki niemu można modyfikować pytania utworzone automatycznie do docelowego wyglądu.<br> Czy na pewo chcesz skonwertować anketę do wyglądu docelowego? ',
      buttons: [
        { text: t('Zamknij'), variant: 'secondary', onClick: handleCloseDialog },
        { text: t('Tak'), variant: 'primary', onClick: TransformQuestions },
      ],
    });
    setShowDialog(true);
  }

  const handleCleanSchema = () => {
    setDialogProps({
      title: t("Czy chcesz kontynuować?"),
      contentTxt: t("Chcesz wyczyścić aktualną konfigurację ankiety?"),
      buttons: [
        { text: t('Tak'), variant: 'primary', onClick: handleFormClear },
        { text: t('Nie'), variant: 'secondary', onClick: handleCloseDialog }
      ]
    });
    setShowDialog(true);
  }

  const handleFormClear = () => {
    handleCloseDialog();
    setQuestionnaire(defaultQuestionnaire);
    setQuestions([defaultQuestionCfg]);
  }

  const handleSaveQuestionnaire = async () => {

    const errors = await formik.validateForm();
    if (Object.keys(errors).length > 0) {
      formik.setTouched(Object.keys(errors).reduce((acc, key) => ({
        ...acc,
        [key]: true
      }), {}));
      return;
    }

    let returnJSON, toastTitle, toastMessage, toastDelay;

    const questionnaireToSave = { ...questionnaire };
    if (type == 'questionnaire') {
      questionnaireToSave.questionsCfg = questionsTransform;
    } else if (type == 'template') {
      questionnaireToSave.questionsCfg = questions;
    }

    setShowSpinner(true);

    // TODO newly created questionnaire/template shouldn't have an id at all
    // TODO handle errors, everywhere in the same way
    if (questionnaireToSave.id == 0) {
      delete questionnaireToSave.id
      console.log('saving questionnaire', questionnaireToSave, JSON.stringify(questionnaireToSave));
      returnJSON = await createQuestionnaire(questionnaireToSave);
      if (returnJSON.id) {
        questionnaireToSave.id = returnJSON.id;
        setQuestionnaire(questionnaireToSave);
      }
    } else {
      console.log('updating questionnaire', questionnaireToSave, JSON.stringify(questionnaireToSave));
      returnJSON = await updateQuestionnaire(questionnaireToSave);
    }

    if (returnJSON.ok) {
      toastTitle = t('OK');
      toastMessage = t('Dane zapisano prawidłowo.');
      toastDelay = 3000;
    } else if (returnJSON.error) {
      toastTitle = t('BŁĄD');
      toastMessage = t('Nie udało się zapisać danych.');
      toastDelay = 0;
    }

    setShowSpinner(false);
    setToastProps({
      title: toastTitle,
      message: toastMessage,
      delay: toastDelay,
      position: 'middle-center',
      hideToast: toastClose
    });
    setShowToast(true);
    handleCloseDialog();
  };

  const handleSelectQuestionChange = (index, event) => {
    const updateQuestions = [...questions];
    updateQuestions[index].type = event.target.value;
    switch (event.target.value) {
      case FormModule.INPUT:
        updateQuestions[index]['cfg'] = { validation: 'empty', minLength: 0, maxLength: 0 };
        break;
      case FormModule.TEXTAREA:
        updateQuestions[index]['cfg'] = { minLength: 0, maxLength: 0 };
        break;
      case FormModule.RADIO:
        updateQuestions[index]['cfg'] = { visualization: 'horizontal', variantCfg: [{ variant: '' }] };
        break;
      case FormModule.CHECKBOX:
        updateQuestions[index]['cfg'] = { variantCfg: [{ variant: '' }], minChecked: 0, maxChecked: 0, visualization: 'horizontal' };
        break;
      case FormModule.INFOBLOCK:
        updateQuestions[index]['cfg'] = {};
        break;
      case FormModule.INPUT_FILE:
        updateQuestions[index]['cfg'] = { type: 'photo', quantity: 'single' };
        break;
      case FormModule.CROSSPROD:
        updateQuestions[index]['cfg'] = { templateId: null, idProductList: 0 };
        break;
      case FormModule.SELECT:
        updateQuestions[index]['cfg'] = { minChecked: 1, maxChecked: 1, sizeList: 1, variantCfg: [{ variant: '' }] };
        break;
      case FormModule.BLOCKTEMPLATE:
        updateQuestions[index]['cfg'] = { templateId: null };
        break;
      case FormModule.DATEHOUR:
        updateQuestions[index]['cfg'] = { type: 'date' };
        break;
      case FormModule.PICTURES:
        updateQuestions[index]['cfg'] = { minQuantity: 1, maxQuantity: 1 };
        break;
      default:
        break;
    }
    setQuestions(updateQuestions);
  };

  const findMaxIdFieldValue = (questionsCfg) => {
    let maxIdFieldValue = 0;

    questionsCfg.forEach((question) => {
      const idFieldValue = parseInt(question.idField, 10);
      if (idFieldValue > maxIdFieldValue) {
        maxIdFieldValue = idFieldValue;
      }
    });

    return maxIdFieldValue;
  };

  const addQuestion = () => {
    let maxIdField = findMaxIdFieldValue(questions);
    const adddefaultQuestionCfg = defaultQuestionCfg;
    maxIdField++;
    adddefaultQuestionCfg.idField = maxIdField;
    setQuestions([...questions, adddefaultQuestionCfg]);
  };

  const deleteQuestion = (index) => {
    const newQuestions = [...questions];
    newQuestions.splice(index, 1);
    setQuestions(newQuestions);
  };

  const updateQuestion = (index, event) => {
    const updateQuestions = [...questions];
    updateQuestions[index].question = event.target.value;
    setQuestions(updateQuestions);
  };

  const moveUpQuestion = (index) => {
    const newQuestions = [...questions];
    let temp = newQuestions[index - 1];
    newQuestions[index - 1] = newQuestions[index];
    newQuestions[index] = temp;
    setQuestions(newQuestions);
  };

  const moveDownQuestion = (index) => {
    const newQuestions = [...questions];
    let temp = newQuestions[index + 1];
    newQuestions[index + 1] = newQuestions[index];
    newQuestions[index] = temp;
    setQuestions(newQuestions);
  };

  const handleCfgChange = (event, field) => {
    const { value } = event.target;
    const updateQuestionnaire = { ...questionnaire };
    if (field === 'name') {
      updateQuestionnaire.name = value;
    } else if (field === 'description') {
      updateQuestionnaire.description = value;
    } else if (field === 'archive') {
      updateQuestionnaire.archive = !updateQuestionnaire.archive;
    }
    setQuestionnaire(updateQuestionnaire);
  };


  const getQuestionRowClass = (index) => {
    const isSecondary = index % 2 === 0;
  
    let className = isSecondary ? 'secondaryQuestion' : 'firstQuestion';
    className += ' mt-3 ';
  
    return className;
  };  

  return (
    <>
      <Dialog
        title={dialogProps.title}
        contentTxt={dialogProps.contentTxt}
        show={showDialog}
        buttons={dialogProps.buttons}
        dialogContentComponent={dialogProps.dialogContentComponent}
        dialogSize={dialogProps.dialogSize}
        dialogClassName="unique-modal-QuestionnaireEditor"
      />
      <Toast
        toastMessage={toastProps.message}
        toastTitle={toastProps.title}
        delay={toastProps.delay}
        show={showToast}
        position={toastProps.position}
        toastTitleSmall={toastProps.toastTitleSmall}
        hideToast={toastProps.hideToast}
      />
      <Form>
        {showMenu && (
          <>
            <Row>
              <Col md={1} className="d-flex flex-column justify-content-center mt-2 mb-2">
                {t('Nazwa')}
              </Col>
              <Col md={11} className="mt-2 mb-2">
                <Form.Control
                  type="text"
                  name='name'
                  maxLength={defaultQuestionnaireNameLength}
                  value={formik.values[`name`]}
                  onChange={(e) => {
                    formik.handleChange(e);
                    handleCfgChange(e, 'name')
                  }}
                  className="form-control"
                />
                {formik.touched[`name`] && formik.errors[`name`] ? (
                  <Col className="text-danger fs-6">{formik.errors[`name`]}</Col>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col md={1} className="d-flex flex-column justify-content-center mt-2 mb-2">
                {t('Opis')}
              </Col>
              <Col md={11} className="mt-2 mb-2">
                <FormControl
                  as="textarea"
                  value={formik.values[`description`]}
                  name='description'
                  maxLength={defaultQuestionnaireDescriptionLength}
                  onChange={(e) => {
                    formik.handleChange(e);
                    handleCfgChange(e, 'description')
                  }}
                  className="form-control"
                />
                {formik.touched[`description`] && formik.errors[`description`] ? (
                  <Col className="text-danger fs-6">{formik.errors[`description`]}</Col>
                ) : null}
              </Col>
            </Row>
            {(questionnaire.id !== 0) && (
              <Row className="mt-2">
                <Col md={1} className="d-flex flex-column justify-content-center mt-2 mb-2">
                  {t('Archiwum')}
                </Col>
                <Col md={11} className="mt-2 mb-2">
                  <Form.Check
                    type="checkbox"
                    checked={questionnaire?.archive}
                    onChange={(e) => handleCfgChange(e, 'archive')}
                  />
                </Col>
              </Row>
            )}
            <hr className="border-2" />
            {questions.map((question, index) => (
              <Row key={index} className={getQuestionRowClass(index)}>
                <Col md={6} >
                  <Form.Label htmlFor={`question-${index}`} className="fs-8">
                    {t('moduł')} {index + 1}
                  </Form.Label>
                  <Col className="input-group">
                    <FormControl
                      as="textarea"
                      id={`question-${question.idField}`}
                      name={`question-${question.idField}`}
                      value={formik.values[`question-${question.idField}`]}
                      onChange={(e) => {
                        formik.handleChange(e);
                        updateQuestion(index, e);
                      }}
                      onBlur={formik.handleBlur}
                      className="form-control"
                    />
                    <Col md={1} className="input-group-append">
                      <Button
                        type="button"
                        onClick={() => deleteQuestion(index)}
                        variant="outline-secondary"
                      >
                        <i className="bi bi-trash"></i>
                      </Button>
                      <br />
                      <Button
                        type="button"
                        onClick={() => moveUpQuestion(index)}
                        disabled={index === 0}
                        variant="outline-secondary"
                      >
                        <i className="bi bi-arrow-up"></i>
                      </Button>
                      <br />
                      <Button
                        type="button"
                        onClick={() => moveDownQuestion(index)}
                        disabled={index === questions.length - 1}
                        variant="outline-secondary"
                      >
                        <i className="bi bi-arrow-down"></i>
                      </Button>
                    </Col>
                  </Col>
                  {formik.touched[`question-${question.idField}`] && formik.errors[`question-${question.idField}`] ? (
                    <Col className="text-danger fs-6">{formik.errors[`question-${question.idField}`]}</Col>
                  ) : null}
                </Col>
                <Col md={6}>
                    <QuestionnaireTypeQuestion
                      index={index}
                      handleChange={handleSelectQuestionChange}
                      value={question.type || ''}
                    />
                    <QuestionnaireTypeQuestionConfiguration
                      index={index}
                      questions={questions}
                      setQuestions={setQuestions}
                      formik={formik}
                    />
                </Col>
              </Row>
            ))}
            <Col className="sticky-bottom d-flex justify-content-center">
              <Col className="FixedBottomButtons">
                <Button type="button" onClick={addQuestion} variant="primary">
                  <i className="bi bi-file-earmark-plus"></i>&nbsp;
                  {t("dodaj moduł")}
                </Button>
                {type == 'questionnaire' && (
                  <Button type="button" onClick={handleTransformQuestions} variant="primary">
                    <i className="bi bi-arrows-move ml-3 mr-3"></i>&nbsp;
                    {t('Przekształć oryginał na docelową')}
                  </Button>
                )}
                <Button type="button" onClick={handleSaveQuestionnaire} variant="primary">
                  <i className="bi bi-floppy ml-3 mr-3"></i>&nbsp;
                  {t("zapisz konfigurację")}
                </Button>
                <Button type="button" onClick={handleCleanSchema} variant="primary">
                  <i className="bi bi-trash ml-3 mr-3"></i>&nbsp;
                  {t("wyczyść ankietę")}
                </Button>
                <Button type="button" onClick={handlePreview} variant="primary">
                  <i className="bi bi-graph-up-arrow"></i>&nbsp;
                  {t("podgląd ankiety")}
                </Button>
                {user.modules.includes('dev') && (
                  <>
                    <Button type="button" onClick={() => handleCopyToClipboard(JSON.stringify(questions))} variant="outline-secondary">
                      <i className="bi bi-copy"></i>&nbsp;
                      {t('Skopiuj oryginał do schowka')}
                    </Button>
                    <Button type="button" onClick={() => handleCopyToClipboard(JSON.stringify(questionsTransform))} variant="outline-secondary">
                      <i className="bi bi-copy"></i>&nbsp;
                      {t('Skopiuj docelowa do schowka')}
                    </Button>
                  </>
                )}
              </Col>
            </Col>
          </>
        )}
        {showSpinner && (<Spinner />)}
        <Row>
          <Col><pre>{JSON.stringify(questions, null, 2)}</pre></Col>
          <Col><pre>{JSON.stringify(initialValues, null, 2)}</pre></Col>
        </Row>
        <Row>
          <Col><pre>{JSON.stringify(validationSchema, null, 2)}</pre></Col>
        </Row>
      </Form>
    </>
  );
};

export default DynamicForm;
