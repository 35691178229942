const conditionalRowStyles = [
    {
        when: row => new Date(row.dataStop) > new Date(),
        style: {
            backgroundColor: '#87CEEB',
            color: 'white',
            '&:hover': {
                cursor: 'not-allowed',
            },
        },
    },
];

export default conditionalRowStyles;
