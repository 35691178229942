export const defaultMaxQuestionStringLength = 10;
export const defaultMaxVariantStringLength = 20;
export const defaultInputMaxLength = 1000;
export const defaultTextareaMaxLength = 1000;
export const defaultCheckboxMaxChecked = 100;
export const defaultSelectMaxChecked = 100;
export const defaultSelectMaxSizeList = 100;
export const defaultSpaceBetweenQuestion = '0.2cm';
export const defaultSpaceLeftDependQuestion = '0.3cm';
export const defaultPicturesMaxQuantity = 10;
export const defaultQuestionnaireNameLength = 250;
export const defaultQuestionnaireDescriptionLength = 1000;