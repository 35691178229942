import React, { useRef, useState } from 'react';
import { Container, Button } from 'react-bootstrap';

const CameraComponent = () => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [photoURL, setPhotoURL] = useState(null);

  const startCamera = async () => {
    try {
      //const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (err) {
      console.error('Error accessing camera:', err);
    }
  };

  const takePhoto = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    if (video && canvas) {
      // Ustawienie rozmiarów canvasa na rozmiary obrazu z kamery
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      const context = canvas.getContext('2d');

      // Odwrócenie obrazu poziomo
      context.scale(-1, 1);
      context.drawImage(video, 0, 0, canvas.width * -1, canvas.height);
      context.scale(-1, 1); // Przywrócenie normalnego odwzorowania

      // Konwersja obrazu canvasa na format JPEG
      const photoDataURL = canvas.toDataURL('image/jpeg');
      setPhotoURL(photoDataURL);
    }
  };

  return (
    <Container>
      <Button onClick={startCamera} >Start</Button>
      <Button onClick={takePhoto} style={{ marginLeft: 2 + 'em' }}>Zdjęcie</Button>
      <br />
      <video ref={videoRef} autoPlay muted style={{ width: 'auto', height: 'auto', transform: 'scaleX(-1)' }} />
      <br/>
      <canvas ref={canvasRef} style={{ display: 'none' }} />
      {photoURL && <img src={photoURL} alt="Zdjęcie" style={{ width: 'auto', height: 'auto' }} />}
    </Container>
  );
};

export default CameraComponent;
