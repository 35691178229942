import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { UserProvider } from './components/context/UserContext';
import { DictionaryProvider } from './components/context/DictionaryContext';
import NavBarHead from './components/layout/NavBarHead';
import LoginForm from './components/forms/LoginForm';
import PasswordChange from './components/forms/PasswordChange';
import Menu from './components/Menu';
import BrakLinku from './components/BrakLinku';
import Administracja from './components/Administracja';

import 'bootstrap/dist/css/bootstrap.min.css';
import './css/global.css';
import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <UserProvider>
      <DictionaryProvider>
        <Router>
          <div style={{ maxWidth: '100%' }}>
            <NavBarHead />
            <Routes>
              <Route path="/" element={<LoginForm />} />
              <Route path="/logowanie" element={<LoginForm />} />
              <Route path="/konsola/*" element={<Menu />} />
              <Route path="/administracja/*" element={<Administracja />} />
              <Route path="/passwordchange/*" element={<PasswordChange />} />
              <Route path="*" element={<BrakLinku />} />
            </Routes>
          </div>
        </Router>
      </DictionaryProvider>
    </UserProvider>
  </React.StrictMode>
);