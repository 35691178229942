import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { defaultMaxVariantStringLength } from '../QuestionnaireCfg';
import QuestionnaireTypeQuestionDependentQuestions from './QuestionnaireTypeQuestionDependentQuestions';

const QuestionnaireTypeQuestionRadio = ({ index, questions, setQuestions }) => {

    const { t } = useTranslation();
    const defaultVariantCfg = { variant: '' };
    const [variants, setVariants] = useState(
        questions[index]?.cfg?.variantCfg ? questions[index].cfg.variantCfg : [defaultVariantCfg]
    );
    const [isNewVariantAdded, setIsNewVariantAdded] = useState(false);
    const newVariantRef = useRef(null);

    const updateVariant = (index, event) => {
        const updatedVariants = [...variants];
        updatedVariants[index].variant = event.target.value;
        setVariants(updatedVariants);
        setIsNewVariantAdded(false);
    };

    const addVariant = () => {
        const newVariants = [...variants, defaultVariantCfg];
        setVariants(newVariants);
        setIsNewVariantAdded(true);
    };

    const deleteVariant = (index) => {
        const newVariants = [...variants];
        newVariants.splice(index, 1);
        setVariants(newVariants);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            addVariant();
        }
    };

    useEffect(() => {
        const updatedQuestions = [...questions];
        updatedQuestions[index]['cfg'].variantCfg = variants;
        setQuestions(updatedQuestions);
    }, [variants, index]);

    const handleCfgChange = (event, filed) => {
        const updatedQuestions = [...questions];
        updatedQuestions[index]['cfg'][filed] = event.target.value;
        setQuestions(updatedQuestions);
    };

    useEffect(() => {
        if (isNewVariantAdded && newVariantRef.current) {
            newVariantRef.current.focus();
        }
    }, [variants, isNewVariantAdded]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <div className="input-group mb-3">
                {variants.map((variant, index) => (
                    <div key={index} className="input-group mb-3">
                        <div style={{ width: '80px' }}>{t('wariant')}</div>
                        <input
                            ref={index === variants.length - 1 ? newVariantRef : null}
                            id={`variant-${index}`}
                            type="text"
                            maxLength={defaultMaxVariantStringLength}
                            value={variant.variant || ''}
                            onChange={(event) => updateVariant(index, event)}
                            onKeyPress={handleKeyPress}
                            className="form-control"
                        />
                        <button
                            type="button"
                            onClick={() => deleteVariant(index)}
                            className="btn btn-outline-secondary"
                        >
                            <i className="bi bi-trash"></i>
                        </button>
                    </div>
                ))}
            </div>
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                <div style={{ width: '80px' }}>{t('wizualizacja')}</div>
                <select
                    onChange={(event) => handleCfgChange(event, 'visualization')}
                    value={questions[index]?.cfg?.visualization}
                    className="form-control"
                >
                    <option value="vertical">{t('pionowa')}</option>
                    <option value="horizontal">{t('pozioma')}</option>
                </select>
            </div>
            <button type="button" onClick={addVariant} className="btn btn-primary mr-1">
                {t('dodaj wariant')}
            </button>
            <QuestionnaireTypeQuestionDependentQuestions index={index} questions={questions} setQuestions={setQuestions} />
        </div>
    );
};

export default QuestionnaireTypeQuestionRadio;
