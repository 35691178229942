import React from 'react';
import { useUser } from './context/UserContext';
import { Container, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const Info = () => {

  const { user } = useUser();
  const { i18n } = useTranslation();

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <Container>
      <h2>Strona informacyjna</h2>
      {user &&
        <>
          <p>Zalogowany użytkownik: {user.username}</p>{JSON.stringify(user)}
          <hr />
          <Button onClick={() => changeLanguage('en')} className="shadow" style={{ marginRight: '1em' }}>Angielski</Button>
          <Button onClick={() => changeLanguage('pl')} className="shadow" style={{ marginRight: '1em' }}>Polski</Button>
        </>}
    </Container>
  );
};

export default Info;