import React, { useState, useEffect } from 'react';
import { Toast as BootstrapToast, ToastContainer } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const Toast = ({ toastMessage, toastTitle, delay, show, hideToast, position, toastTitleSmall }) => {
  const [counter, setCounter] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    let timerId;
    if (show && delay) {
      setCounter(delay / 1000);
      timerId = setInterval(() => {
        setCounter(prevCounter => prevCounter - 1);
      }, 1000);
    } else if (show && !delay) {
      setCounter(0);
      timerId = setInterval(() => {
        setCounter(prevCounter => prevCounter + 1);
      }, 1000);
    }
    return () => clearInterval(timerId);
  }, [show, delay]);

  const renderSmallInfo = () => {
    if (toastTitleSmall) {
      return toastTitleSmall;
    } else if (delay) {
      return t('Zamknięcie za') + ' ' + counter + ' ' + t('sekund');
    } else if (!delay) {
      return t('Otwarty') + ' ' + counter + ' ' + t('sekund');
    }
    return null;
  };

  return (
    <ToastContainer position={position} className="p-3">
      <BootstrapToast show={show} onClose={hideToast} {...(delay && { delay, autohide: true })}>
        <BootstrapToast.Header>
          <strong className="me-auto">{toastTitle}</strong>
          <small>{renderSmallInfo()}</small>
        </BootstrapToast.Header>
        <BootstrapToast.Body>{toastMessage}</BootstrapToast.Body>
      </BootstrapToast>
    </ToastContainer>
  );
};

export default Toast;
