import React, { forwardRef } from 'react';
import { defaultSpaceBetweenQuestion, defaultSpaceLeftDependQuestion } from '../QuestionnaireCfg';

const QuestionnaireRenderQuestionInfoblok = forwardRef(({ index, question, formik, counter }, ref) => {

  if (!question.show) return null;

  return (
    <div
      ref={ref}
      id={`mod-${question.idField}`}
      className="row questionInfoblock"
      style={{ marginLeft: 0, paddingTop: defaultSpaceBetweenQuestion, paddingBottom: defaultSpaceBetweenQuestion, marginTop: defaultSpaceBetweenQuestion, marginBottom: defaultSpaceBetweenQuestion, marginRight: 0 }}
    >
      <div style={{
        paddingLeft: defaultSpaceLeftDependQuestion
      }}>
        <div style={{ whiteSpace: 'pre-wrap' }}>
          <hr className="patternH1" />
          {question.question}
          <hr className="patternH1" />
        </div>
      </div>
    </div>
  );
});

export default QuestionnaireRenderQuestionInfoblok;