import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const smallerButtonStyle = {
    padding: '0.25rem 0.5rem',
    fontSize: '0.875rem', // mniejsza czcionka
};

const columns = [
    {
        name: 'ID',
        selector: row => row.id,
        sortable: true,
        width: '5%',
        cell: row => (
            row.id < 5000 && ( // Dodaj warunek, aby przycisk wyświetlał się tylko dla row.id < 5000
                <Link
                    to={`/produkty/edycja/${row.id}`}
                    style={{ color: 'red', textDecoration: 'none' }}
                >
                    {row.id}
                </Link>
            )
        ),        
    },
    {
        name: 'Nazwa',
        selector: row => row.Nazwa,
        sortable: true,
        width: '55%',
    },
    {
        name: 'Kod',
        selector: row => row.Kod,
        sortable: true,
        width: '20%',
    },
    {
        name: 'Administracja',
        cell: row => (
            row.id < 5000 && ( // Dodaj warunek, aby przycisk wyświetlał się tylko dla row.id < 5000
                <Link
                    to={`/produkty/edycja/${row.id}`}
                    style={{ color: 'red', textDecoration: 'none' }}
                >
                    Edycja
                </Link>
            )
        ),
    },
];

export default columns;
