import React, { useContext, useEffect } from 'react';
import { Nav, Button, Navbar } from 'react-bootstrap';
import UserContext from '../context/UserContext';
import appConfiguration from '../cfg/AppCfg';
import { useTranslation } from 'react-i18next';

const NavBarHead = () => {

  const { t, i18n } = useTranslation();
  const { user, onLogout, handleReturnUserOrg } = useContext(UserContext);
  document.title = appConfiguration.documentTitle;

  useEffect(() => {
    if (user) {
      document.documentElement.style.setProperty('--kolor_aplikacji', user.layout);
      i18n.changeLanguage(user.language);
    }
  }, [user]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const message = t("Ta strona prosi o potwierdzenie, że chcesz ją opuścić — wprowadzone informacje mogą nie zostać zapisane.");
      event.returnValue = message;
      return message;
    };
    // aby nie denerwowało przy programowaniu
    //window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <Navbar expand="lg">
      <Navbar.Brand style={{ marginLeft: '1em' }}>{appConfiguration.documentTitle}</Navbar.Brand>
      <Nav className="me-auto">
      </Nav>
      <Nav className="ml-auto">
        {
          (user && user.orgUser && user.username !== user.orgUser) ? (
            <Button onClick={handleReturnUserOrg} className="shadow btn-warning" style={{ marginRight: 1 + 'em' }}>
              powrót : {user.orgUser}
            </Button>
          ) : null

        }
        {user ? (
          <>
            <Button onClick={onLogout} className="shadow" style={{ marginRight: 1 + 'em' }}>
              <i className="bi bi-door-open"></i> {user.username}
            </Button>
          </>
        ) : null}
      </Nav>
    </Navbar>
  );
};

export default NavBarHead;