import React, { useState, useEffect } from "react";
import { Button, Container, Row, Col, Form, Alert } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import * as Yup from "yup";
import { Formik } from "formik";
import SelectWrapperJson from '../common/SelectWrapperJson';

const ProductForm = () => {

  const [initialValues, setInitialValues] = useState({
    Produkt: '',
    Klient: '',
    Telefon: '',
    Email: '',
    Adres: '',
    KodPocztowy: '',
    Miejscowosc: '',
    NIP: '',
    KodKontrahenta: '',
    OsobaKontaktowa: '',
    PrzypisanieASMPM: '',
  });

  const { id } = useParams();
  const navigate = useNavigate();

  let infoContent = id === '0' ? 'Dodaj nowy produkt' : 'Edycja produktu';

  useEffect(() => {
    let isMounted = true;

    if (id > 0 || !id) {
      fetch(`/json/product.json`)
        .then(response => response.json())
        .then(data => {
          if (isMounted) {
            setInitialValues({
              Produkt: data.Produkt || '',
              Klient: data.Klient || '',
              Brand: data.Brand || '',
              Grupa: data.Grupa || '',
              KODASM: data.KODASM || '',
              KOD: data.KOD || '',
              EAN: data.EAN || '',
              Opis: data.questionnaireDescription || '',
            });
          }
        })
        .catch(error => {
          if (isMounted) {
            alert('Błąd podczas odbierania danych');
            navigate("/konsola/produkty/lista");
          }
        });
    }
    return () => {
      isMounted = false;
    };
  }, [id, navigate]);

  const validationSchema = Yup.object({
    Produkt: Yup.string().required('Nazwa produktu jest wymagana'),
    Klient: Yup.string().required('Przypisanie klienta jest wymagane'),
    KODASM: Yup.string().required('Kod produktu ASM jest wymagany'),
  });

  const onSubmit = async (values, { resetForm }) => {
    try {
      const response = await fetch('http://data.pl/klient', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(values)
      });
      if (!response.ok) {
        throw new Error('Wystąpił problem podczas wysyłania danych.');
      }
      resetForm();
      navigate("/konsola/klienci/lista");
    } catch (error) {
      console.error('Błąd podczas wysyłania danych:', error);
      alert('Wystąpił problem podczas wysyłania danych.');
    }
  };


  return (
    <Container style={{ border: '1px solid #ccc', padding: '20px' }}>
      <Alert variant="info" className="text-center mb-3"><b>{infoContent}</b></Alert>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Control
              type="hidden"
              name="idProduct"
              value={id}
            />
            <Form.Group controlId="formUsername" className="mb-3">
              <Form.Label>Nazwa produktu</Form.Label>
              <Form.Control
                type="text"
                name="Produkt"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.Produkt}
                isInvalid={touched.Produkt && !!errors.Produkt}
              />
              {touched.Produkt && errors.Produkt && (
                <Form.Control.Feedback type="invalid">
                  {errors.Produkt}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Row className="mb-3">
              <Col>
                <Form.Group controlId="formKlient">
                  <Form.Label>Klient</Form.Label>
                  <Form.Control
                    as="select"
                    name="Klient"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.Klient}
                    isInvalid={touched.Klient && !!errors.Klient}
                  >
                    <SelectWrapperJson
                      json={'/json/produkty.json'}
                      firstOption='wybierz'
                      fieldOptionText='Nazwa'
                      fieldOptionValue='id'
                    />
                  </Form.Control>
                  {touched.Klient && errors.Klient && (
                    <Form.Control.Feedback type="invalid">
                      {errors.Klient}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formBrand">
                  <Form.Label>Brand</Form.Label>
                  <Form.Control
                    name="Brand"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.Brand}
                    isInvalid={touched.Brand && !!errors.Brand}
                  />
                  {touched.Brand && errors.Brand && (
                    <Form.Control.Feedback type="invalid">
                      {errors.Brand}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formGrupa">
                  <Form.Label>Grupa</Form.Label>
                  <Form.Control
                    name="Grupa"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.Grupa}
                    isInvalid={touched.Grupa && !!errors.Grupa}
                  />
                  {touched.Grupa && errors.Grupa && (
                    <Form.Control.Feedback type="invalid">
                      {errors.Grupa}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Form.Group controlId="formKODASM">
                  <Form.Label>Kod produktu wg ASM</Form.Label>
                  <Form.Control
                    name="KODASM"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.KODASM}
                    isInvalid={touched.KODASM && !!errors.KODASM}
                  />
                  {touched.KODASM && errors.KODASM && (
                    <Form.Control.Feedback type="invalid">
                      {errors.KODASM}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formKOD">
                  <Form.Label>Kod produktu wg Klienta</Form.Label>
                  <Form.Control
                    name="KOD"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.KOD}
                    isInvalid={touched.KOD && !!errors.KOD}
                  />
                  {touched.KOD && errors.KOD && (
                    <Form.Control.Feedback type="invalid">
                      {errors.KOD}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formEAN">
                  <Form.Label>Kod EAN</Form.Label>
                  <Form.Control
                    name="EAN"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.EAN}
                    isInvalid={touched.EAN && !!errors.EAN}
                  />
                  {touched.EAN && errors.EAN && (
                    <Form.Control.Feedback type="invalid">
                      {errors.EAN}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Form.Group controlId="formASMPM" className="mb-3">
              <Form.Label>Opis</Form.Label>
              <Form.Control
                as="textarea"
                name="Opis"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.questionnaireDescription}
                isInvalid={touched.questionnaireDescription && !!errors.questionnaireDescription}
              />
              {touched.questionnaireDescription && errors.questionnaireDescription && (
                <Form.Control.Feedback type="invalid">
                  {errors.questionnaireDescription}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Button variant="primary" type="submit" className="w-100 mt-2">
              Zapisz
            </Button>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default ProductForm;
