import React, { createContext, useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchQuestionnaireTemplateList } from '../../services/questionnaire';
import appConfiguration from '../cfg/AppCfg';

const DictionaryContext = createContext();

export const DictionaryProvider = ({ children }) => {

    const { t } = useTranslation();

    const [dictionaryProductsList, setDictionaryProductsList] = useState(null);
    const [dictionaryProducts, setDictionaryProducts] = useState(null);
    const [dictionaryTemplates, setDictionaryTemplates] = useState([]);

    useEffect(() => {
        const fetchDictionaryProductsList = async () => {
            try {
                const response = await fetch('/json/productsGroupList.json');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setDictionaryProductsList(data);
            } catch (error) {
                console.error('Error fetching dictionary:', error);
            }
        };

        const fetchDictionaryTemplates = async () => {
            const templates = await fetchQuestionnaireTemplateList();
            setDictionaryTemplates(templates);
        };

        const fetchDictionaryProducts = async () => {
            try {
                const response = await fetch('/json/products.json');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setDictionaryProducts(data);
            } catch (error) {
                console.error('Error fetching dictionary:', error);
            }
        };

        fetchDictionaryProductsList();
        fetchDictionaryProducts();
        fetchDictionaryTemplates();

        const interval = setInterval(() => {
            fetchDictionaryProducts();
            fetchDictionaryProductsList();
            fetchDictionaryTemplates();
        }, appConfiguration.defaultCacheDictionary * 1000);

        return () => clearInterval(interval);

    }, []);

    const describeDictionaryValue = (dict, value) => {
        if (!value || !dict) return t('brak');
        let fieldName, row;
        switch (dict) {
            case 'szablonAnkiet':
                fieldName = "name";
                row = dictionaryTemplates.find(item => item.id === value);
                break;
            case 'szablonProduktow':
                fieldName = "listName";
                row = dictionaryProductsList.find(item => item.idProductsList.toString() === value.toString());
                break;
            case 'Produkt':
                fieldName = "Produkt";
                row = dictionaryProducts.find(item => item.idProduct.toString() === value.toString());
                break;
        }
        return row ? row[fieldName] : value;
    }

    return (
        <DictionaryContext.Provider value={{ describeDictionaryValue, dictionaryProducts, dictionaryProductsList, dictionaryTemplates }}>
            {children}
        </DictionaryContext.Provider>
    );
};

export const useDictionary = () => {
    const dictionary = useContext(DictionaryContext);
    if (!dictionary) {
        throw new Error('useDictionary must be used within a DictionaryProvider');
    }
    return dictionary;
};

export default DictionaryContext;