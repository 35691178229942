import React, { useState, useEffect } from 'react';
import DataTableBasejQuery from '../../datatable/table/base/DataTableBasejQuery';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const StoreList = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const columns = [
    {
      "data": "idStore",
      "class": "noVis"
    },
    {
      "title": t("Sieć"),
      "data": "Network",
      "class": "filterbyinput",
      "render": function (data, type, full, meta) {
        return `<a class="jqueryDataTable-link" data-href="/konsola/store/edit/${full.idStore}">${data}</a>`;
      }
    },
    {
      "title": t("Miasto"),
      "data": "City",
      "class": "filterbyinput"
    },
    {
      "title": t("Adres"),
      "data": "Address",
      "class": "filterbyinput"
    },
    {
      "title": t("Kod Hali wg ASM"),
      "data": "WarehouseCodeASM",
      "class": "filterbyinput"
    },
    {
      "title": t("Merch"),
      "data": "Merch",
      "class": "filterbyinput"
    },
    {
      "title": t("PR"),
      "data": "PR",
      "class": "filterbyinput"
    },
    {
      "title": t("KR"),
      "data": "KR",
      "class": "filterbyinput"
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/json/storeList.json');
        const json = await response.json();
        setData(json);
      } catch (error) {
        console.error('Błąd pobierania danych:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {

    const handleClick = event => {
      const element = event.target.closest('.jqueryDataTable-link');
      if (element) {
        event.preventDefault();
        const path = element.getAttribute('data-href');
        navigate(path);
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [navigate]);

  const handleNewStore = () => {
    navigate('/konsola/store/edit/0');
  }

  const aLengthMenu = [
    [10, 20, -1],
    [t('mała'), t('średnia'), t('wszystko')]
  ]

  const columnDefs = [
    {
      visible: false,
      targets: [0]
    },
  ]

  return (
    <>
      <div className="d-flex justify-content-center mb-4">
        <button className="btn btn-outline-primary form-control" onClick={() => handleNewStore()}>{t('dodaj nową halę')}</button>
      </div>
      {data.length > 0 && columns.length && (
        <DataTableBasejQuery
          columns={columns}
          data={data}
          aLengthMenu={aLengthMenu}
          columnDefs={columnDefs}
          localName="StoreList"
          showFooter={false}
        />
      )
      }
    </>
  );
}

export default StoreList;
