import React, { useState, useEffect } from 'react';
import { fetchQuestionnaireList } from '../../../services/questionnaire';
import DataTableBasejQuery from '../../datatable/table/base/DataTableBasejQuery';
import Spinner from '../../common/Spinner';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const QuestionnaireList = () => {

  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const { t } = useTranslation();

  const columns = [
    {
      "data": "id",
      "class": "noVis",
    },
    {
      "title": t("Nazwa"),
      "data": "name",
      "class": "filterbyinput",
      "render": function (data, type, full, meta) {
        return `<a class="jqueryDataTable-link" data-href="/konsola/ankiety/${full.id}">${data}</a>`;
      },
    },
    {
      "title": t("Archiwum"),
      "data": "archive",
      "class": "filterbyselect",
      "render": function (data, type, full, meta) {
        return data;
      },
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const questionnaires = await fetchQuestionnaireList();
      const processedQuestionnaires = questionnaires.map(item => ({
        ...item,
        archive: item.archive ? t('archiwalna') : t('aktywna')
      }));
      setData(processedQuestionnaires);
    };
    fetchData();
  }, []);

  useEffect(() => {

    const handleClick = event => {
      const element = event.target.closest('.jqueryDataTable-link');
      if (element) {
        event.preventDefault();
        const path = element.getAttribute('data-href');
        navigate(path);
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [navigate]);

  const handleNewTemplate = () => {
    navigate('/konsola/ankiety/0');
  };

  const aLengthMenu = [
    [10, 20, -1],
    [t('mała'), t('większa'), t('wszystko')],
  ];

  const columnDefs = [
    {
      visible: false,
      targets: [0, 2],
    },
  ];

  return (
    <>
      <div className="d-flex justify-content-center mb-4">
        <button className="btn btn-outline-primary form-control"
          onClick={() => handleNewTemplate()}>{t("dodaj nową ankietę")}</button>
      </div>
      {data.length ? (
        <DataTableBasejQuery
          columns={columns}
          data={data}
          showFooter={false}
          aLengthMenu={aLengthMenu}
          columnDefs={columnDefs}
          localName={'questionnaireList'}
        />
      ) : <Spinner />}
    </>
  );
};

export default QuestionnaireList;