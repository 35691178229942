import React, { useState, useEffect } from "react";
import { Button, Container, Row, Col, Form, Alert } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import * as Yup from "yup";
import { Formik } from "formik";
import SelectWrapperJson from '../common/SelectWrapperJson';
import { useTranslation } from "react-i18next";

const StoreForm = () => {

  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const infoContent = id === '0' ? t('DODAJ NOWĄ HALĘ') : t('SZCZEGÓŁY HALI');

  const [initialValuesPrimary, setInitialValuesPrimary] = useState({
    Network: 'przykładowy',
    Address: 'Polna xyz',
    Province: 's9t0u1',
    City: 'WarszawA',
    PostalCode: '12-345',
    Format: 'CNV',
    GPSLatitude: '100',
    GPSLongitude: '200',
    WarehouseCodeASM: 'abc',
    ClientWarehouseCode: 'slx',
    ServiceType: '1',
    VisitCoefficientPR: '3',
    VisitCoefficientKR: '2'
  });

  const [initialValuesSecondary, setInitialValuesSecondary] = useState({
    personAttendant: 's9t0u1',
    personASMKH: 'v2w3x4',
    personASMSV: 'y5z6a7',
    personASMPR: 'b8c9d0',
    personASMKR: 'e1f2g3',
  });

  const validationSchemaPrimary = Yup.object({
    Network: Yup.string().required(t('Sieć jest wymagana')),
    Address: Yup.string().required(t('Adres jest wymagany')),
    Province: Yup.string().required(t('Województwo jest wymagane')),
    City: Yup.string().required(t('Miasto jest wymagane')),
    PostalCode: Yup.string().required(t('Kod pocztowy jest wymagany')),
    Format: Yup.string().required(t('Format jest wymagany')),
    GPSLatitude: Yup.string().required(t('Szerokość geograficzna GPS jest wymagana')),
    GPSLongitude: Yup.string().required(t('Długość geograficzna GPS jest wymagana')),
    WarehouseCodeASM: Yup.string().required(t('Kod magazynu ASM jest wymagany')),
    ClientWarehouseCode: Yup.string().required(t('Kod magazynu klienta jest wymagany')),
    ServiceType: Yup.string().required(t('Rodzaj serwisu jest wymagany')),
    VisitCoefficientPR: Yup.string().required(t('Współczynnik odwiedzin PR jest wymagany')),
    VisitCoefficientKR: Yup.string().required(t('Współczynnik odwiedzin KR jest wymagany')),
  });

  const validationSchemaSecondary = Yup.object({
    personAttendant: Yup.string().required(t('Osoba osgługująca jest wymagana')),
    personASMKH: Yup.string().required(t('Osoba ASM KH jest wymagana')),
    personASMSV: Yup.string().required(t('Osoba ASM SV jest wymagana')),
    personASMPR: Yup.string().required(t('Osoba ASP PR jest wymagana')),
    personASMKR: Yup.string().required(t('Osoba ASM KR jest wymagana')),
  });

  const onSubmitPrimary = async (values, { resetForm }) => {
    try {
      const response = await fetch('http://data.pl/klient', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(values)
      });
      if (!response.ok) {
        throw new Error('Wystąpił problem podczas wysyłania danych.');
      }
      resetForm();
      navigate("/konsola/klienci/lista");
    } catch (error) {
      console.error('Błąd podczas wysyłania danych:', error);
      alert('Wystąpił problem podczas wysyłania danych.');
    }
  };

  const onSubmitSecondary = async (values, { resetForm }) => {
    try {
      const response = await fetch('http://data.pl/klient', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(values)
      });
      if (!response.ok) {
        throw new Error('Wystąpił problem podczas wysyłania danych.');
      }
      resetForm();
      navigate("/konsola/klienci/lista");
    } catch (error) {
      console.error('Błąd podczas wysyłania danych:', error);
      alert('Wystąpił problem podczas wysyłania danych.');
    }
  };

  return (
    <Container style={{ border: '1px solid #ccc', padding: '20px', width: '100%', maxWidth: '100%', margin: '0 auto' }}>
      <Row>
        <Col md={10}>
          <Alert variant="info" className="text-center mb-3"><b>{infoContent}</b></Alert>
          <Formik
            initialValues={initialValuesPrimary}
            validationSchema={validationSchemaPrimary}
            enableReinitialize={true}
            onSubmit={onSubmitPrimary}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit
            }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Control
                  type="hidden"
                  name="idStore"
                  value={id}
                />
                <Form.Control
                  type="hidden"
                  name="formType"
                  value={'Primary'}
                />
                <Row className="mb-3">
                  <Col md={4}>
                    <Form.Group controlId="formNetwork" className="mb-3">
                      <Form.Label><b>{t('Sieć')}</b></Form.Label>
                      <Form.Control
                        type="text"
                        name="Network"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.Network}
                        isInvalid={touched.Network && !!errors.Network}
                      />
                      {touched.Network && errors.Network && (
                        <Form.Control.Feedback type="invalid">
                          {errors.Network}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={8}>
                    <Form.Group controlId="formAddress" className="mb-3">
                      <Form.Label><b>{t('Adres')}</b></Form.Label>
                      <Form.Control
                        type="text"
                        name="Address"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.Address}
                        isInvalid={touched.Address && !!errors.Address}
                      />
                      {touched.Address && errors.Address && (
                        <Form.Control.Feedback type="invalid">
                          {errors.Address}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <Form.Group controlId="formProvince">
                      <Form.Label><b>{t('Województwo')}</b></Form.Label>
                      <Form.Control
                        as="select"
                        name="Province"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.Province}
                        isInvalid={touched.Province && !!errors.Province}
                      >
                        <SelectWrapperJson
                          json={'/json/provinceList.json'}
                          firstOption='wybierz'
                          fieldOptionText='name'
                          fieldOptionValue='id'
                          defaultSelected={values.Province}
                        />
                      </Form.Control>
                      {touched.Province && errors.Province && (
                        <Form.Control.Feedback type="invalid">
                          {errors.Province}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="formCity">
                      <Form.Label><b>{t('Miejscowość')}</b></Form.Label>
                      <Form.Control
                        name="City"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.City}
                        isInvalid={touched.City && !!errors.City}
                      />
                      {touched.City && errors.City && (
                        <Form.Control.Feedback type="invalid">
                          {errors.City}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="formPostalCode">
                      <Form.Label><b>{t('Kod pocztowy')}</b></Form.Label>
                      <Form.Control
                        name="PostalCode"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.PostalCode}
                        isInvalid={touched.PostalCode && !!errors.PostalCode}
                      />
                      {touched.PostalCode && errors.PostalCode && (
                        <Form.Control.Feedback type="invalid">
                          {errors.PostalCode}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <Form.Group controlId="formFormat">
                      <Form.Label><b>{t('Format')}</b></Form.Label>
                      <Form.Control
                        as="select"
                        name="Format"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.Format}
                        isInvalid={touched.Format && !!errors.Format}
                      >
                        <SelectWrapperJson
                          json={'/json/storeFormat.json'}
                          firstOption='BRAK'
                          fieldOptionText='name'
                          fieldOptionValue='id'
                          defaultSelected={values.Format}
                        />
                      </Form.Control>
                      {touched.Format && errors.Format && (
                        <Form.Control.Feedback type="invalid">
                          {errors.Format}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="formGPSLatitude">
                      <Form.Label><b>{t('GPS Szerokość geogr.')}</b></Form.Label>
                      <Form.Control
                        name="GPSLatitude"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.GPSLatitude}
                        isInvalid={touched.GPSLatitude && !!errors.GPSLatitude}
                      />
                      {touched.GPSLatitude && errors.GPSLatitude && (
                        <Form.Control.Feedback type="invalid">
                          {errors.GPSLatitude}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="formGPSLongitude">
                      <Form.Label><b>{t('GPS Długość geogr.')}</b></Form.Label>
                      <Form.Control
                        name="GPSLongitude"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.GPSLongitude}
                        isInvalid={touched.GPSLongitude && !!errors.GPSLongitude}
                      />
                      {touched.GPSLongitude && errors.GPSLongitude && (
                        <Form.Control.Feedback type="invalid">
                          {errors.GPSLongitude}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <Form.Group controlId="formWarehouseCodeASM">
                      <Form.Label><b>{t('Kod Hali wg ASM')}</b></Form.Label>
                      <Form.Control
                        name="WarehouseCodeASM"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.WarehouseCodeASM}
                        isInvalid={touched.WarehouseCodeASM && !!errors.WarehouseCodeASM}
                      />
                      {touched.WarehouseCodeASM && errors.WarehouseCodeASM && (
                        <Form.Control.Feedback type="invalid">
                          {errors.WarehouseCodeASM}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="formClientWarehouseCode">
                      <Form.Label><b>{t('Kod Hali wg Klienta')}</b></Form.Label>
                      <Form.Control
                        name="ClientWarehouseCode"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.ClientWarehouseCode}
                        isInvalid={touched.ClientWarehouseCode && !!errors.ClientWarehouseCode}
                      />
                      {touched.ClientWarehouseCode && errors.ClientWarehouseCode && (
                        <Form.Control.Feedback type="invalid">
                          {errors.ClientWarehouseCode}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <Form.Group controlId="formServiceType">
                      <Form.Label><b>{t('Rodzaj serwisu')}</b></Form.Label>
                      <Form.Control
                        as="select"
                        name="ServiceType"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.ServiceType}
                        isInvalid={touched.ServiceType && !!errors.ServiceType}
                      >
                        <SelectWrapperJson
                          json={'/json/rodzajSerwisu.json'}
                          fieldOptionText='name'
                          fieldOptionValue='id'
                          defaultSelected={values.ServiceType}
                        />
                      </Form.Control>
                      {touched.ServiceType && errors.ServiceType && (
                        <Form.Control.Feedback type="invalid">
                          {errors.ServiceType}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="formVisitCoefficientPR">
                      <Form.Label><b>{t('Współczynnik miesięcznych odwiedzin tej hali przez PR')}</b></Form.Label>
                      <Form.Control
                        as="select"
                        name="VisitCoefficientPR"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.VisitCoefficientPR}
                        isInvalid={touched.VisitCoefficientPR && !!errors.VisitCoefficientPR}
                      >
                        <SelectWrapperJson
                          json={'/json/wspolczynnikOdwiedzin.json'}
                          fieldOptionText='name'
                          fieldOptionValue='id'
                          defaultSelected={values.VisitCoefficientPR}
                        />
                      </Form.Control>
                      {touched.VisitCoefficientPR && errors.VisitCoefficientPR && (
                        <Form.Control.Feedback type="invalid">
                          {errors.VisitCoefficientPR}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="formVisitCoefficientKR">
                      <Form.Label><b>{t('Współczynnik miesięcznych odwiedzin tej hali przez KR')}</b></Form.Label>
                      <Form.Control
                        as="select"
                        name="VisitCoefficientKR"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.VisitCoefficientKR}
                        isInvalid={touched.VisitCoefficientKR && !!errors.VisitCoefficientKR}
                      >
                        <SelectWrapperJson
                          json={'/json/wspolczynnikOdwiedzin.json'}
                          fieldOptionText='name'
                          fieldOptionValue='id'
                          defaultSelected={values.VisitCoefficientKR}
                        />
                      </Form.Control>
                      {touched.VisitCoefficientKR && errors.VisitCoefficientKR && (
                        <Form.Control.Feedback type="invalid">
                          {errors.VisitCoefficientKR}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Button variant="primary" type="submit" className="w-100 mt-2">
                  {t('Zapisz')}
                </Button>
              </Form>
            )}
          </Formik>
        </Col>
        <Col md={2}>
          <Alert variant="info" className="text-center mb-3"><b>{t('PRZYPISANIA DO HALI ')}</b></Alert>
          <Formik
            initialValues={initialValuesSecondary}
            validationSchema={validationSchemaSecondary}
            enableReinitialize={true}
            onSubmit={onSubmitSecondary}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit
            }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Control
                  type="hidden"
                  name="idStore"
                  value={id}
                />
                <Form.Control
                  type="hidden"
                  name="formType"
                  value={'Secondary'}
                />
                <Row className="mb-3">
                  <Col>
                    <Form.Group controlId="formPersonAttendant">
                      <Form.Label><b>{t('OBSŁUGUJĄCY')}</b></Form.Label>
                      <Form.Control
                        as="select"
                        name="personAttendant"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.personAttendant}
                        isInvalid={touched.personAttendant && !!errors.personAttendant}
                      >
                        <SelectWrapperJson
                          json={'/json/provinceList.json'}
                          firstOption='wybierz'
                          fieldOptionText='name'
                          fieldOptionValue='id'
                          defaultSelected={values.personAttendant}
                        />
                      </Form.Control>
                      {touched.personAttendant && errors.personAttendant && (
                        <Form.Control.Feedback type="invalid">
                          {errors.personAttendant}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <Form.Group controlId="formPersonASMKH">
                      <Form.Label><b>{t('ASM KH')}</b></Form.Label>
                      <Form.Control
                        as="select"
                        name="personASMKH"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.personASMKH}
                        isInvalid={touched.personASMKH && !!errors.personASMKH}
                      >
                        <SelectWrapperJson
                          json={'/json/provinceList.json'}
                          firstOption='wybierz'
                          fieldOptionText='name'
                          fieldOptionValue='id'
                          defaultSelected={values.personASMKH}
                        />
                      </Form.Control>
                      {touched.personASMKH && errors.personASMKH && (
                        <Form.Control.Feedback type="invalid">
                          {errors.personASMKH}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <Form.Group controlId="formPersonASMSV">
                      <Form.Label><b>{t('ASM SV')}</b></Form.Label>
                      <Form.Control
                        as="select"
                        name="personASMSV"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.personASMSV}
                        isInvalid={touched.personASMSV && !!errors.personASMSV}
                      >
                        <SelectWrapperJson
                          json={'/json/provinceList.json'}
                          firstOption='wybierz'
                          fieldOptionText='name'
                          fieldOptionValue='id'
                          defaultSelected={values.personASMSV}
                        />
                      </Form.Control>
                      {touched.personASMSV && errors.personASMSV && (
                        <Form.Control.Feedback type="invalid">
                          {errors.personASMSV}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <Form.Group controlId="formPersonASMPR">
                      <Form.Label><b>{t('ASM PR')}</b></Form.Label>
                      <Form.Control
                        as="select"
                        name="personASMPR"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.personASMPR}
                        isInvalid={touched.personASMPR && !!errors.personASMPR}
                      >
                        <SelectWrapperJson
                          json={'/json/provinceList.json'}
                          firstOption='wybierz'
                          fieldOptionText='name'
                          fieldOptionValue='id'
                          defaultSelected={values.personASMPR}
                        />
                      </Form.Control>
                      {touched.personASMPR && errors.personASMPR && (
                        <Form.Control.Feedback type="invalid">
                          {errors.personASMPR}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <Form.Group controlId="formPersonASMKR">
                      <Form.Label><b>{t('ASM KR')}</b></Form.Label>
                      <Form.Control
                        as="select"
                        name="personASMKR"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.personASMKR}
                        isInvalid={touched.personASMKR && !!errors.personASMKR}
                      >
                        <SelectWrapperJson
                          json={'/json/provinceList.json'}
                          firstOption='wybierz'
                          fieldOptionText='name'
                          fieldOptionValue='id'
                          defaultSelected={values.personASMKR}
                        />
                      </Form.Control>
                      {touched.personASMKR && errors.personASMKR && (
                        <Form.Control.Feedback type="invalid">
                          {errors.personASMKR}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Button variant="primary" type="submit" className="w-100 mt-2">
                  {t('Zapisz')}
                </Button>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </Container>
  );

};

export default StoreForm;