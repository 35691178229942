import { createContext, useContext, useState } from 'react';
import UserContext from '../components/context/UserContext';

const ThemeContextSlx = createContext();

function Form({ zestawDanych }) {
  return (
    <Panel title="Welcome" zestawDanych={zestawDanych}>
      kolor {zestawDanych.kolor}
    </Panel>
  );
}

function Panel({ title, zestawDanych, children }) {
  const { user } = useContext(UserContext);  
  return (
    <section>
      <p>Username: {zestawDanych.username}</p>
      <p>Hasło: {zestawDanych.haslo}</p>
      <p>Title: {title}</p>
      <p>Children: {children}</p>
      <p>Data logowaina : {user.dataDodania}</p>
    </section>
  );
}

export default function HookUserContext() {

  const [haslo] = useState('hasło');
  const [username] = useState("login");
  const [kolor] = useState("red");
  const zestawDanych = { username, haslo, kolor };

  return (
    <ThemeContextSlx.Provider value={{ zestawDanych }}>
      <Form zestawDanych={zestawDanych} />
    </ThemeContextSlx.Provider>
  );
}
