const columns = [
    {
        "data": "idProductsList",
        "class": "noVis"            
    },
    {
        "title": "Nazwa listy",
        "data": "listName",
        "class": "filterbyinput",
        "render": function(data, type, full, meta) {
            return `<a class="jqueryDataTable-link" data-href="/konsola/produkty/listaGrupa/${full.idProductsList}">${data}</a>`;
        }
    }
];

export default columns;
