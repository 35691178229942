const conditionalRowStyles = [
    {
        when: row => row.id < 1000,
        style: {
            backgroundColor: 'rgba(63, 195, 128, 0.9)',
            color: 'white',
            '&:hover': {
                cursor: 'pointer',
            },
        },
    },
    {
        when: row => row.id >= 1000 && row.id < 3500,
        style: {
            backgroundColor: 'rgba(248, 148, 6, 0.9)',
            color: 'white',
            '&:hover': {
                cursor: 'pointer',
            },
        },
    },
    {
        when: row => row.id >= 5000,
        style: {
            backgroundColor: 'rgba(242, 38, 19, 0.9)',
            color: 'white',
            '&:hover': {
                cursor: 'not-allowed',
            },
        },
    },
];

export default conditionalRowStyles;
