import React, { forwardRef } from 'react';
import { defaultSpaceBetweenQuestion, defaultSpaceLeftDependQuestion } from '../QuestionnaireCfg';

const QuestionnaireRenderQuestionTextarea = forwardRef(({ index, question, formik, counter, prefix }, ref) => {

  const fieldName = question.idField;

  if (!question.show) return null;

  return (
    <div
      ref={ref}
      id={`mod-${question.idField}`}      
      className={counter % 2 === 0 ? 'secondaryQuestion' : ''}
      style={{ paddingLeft: defaultSpaceLeftDependQuestion, paddingTop: defaultSpaceBetweenQuestion, paddingBottom: defaultSpaceBetweenQuestion }}
    >
      <div style={{
        paddingLeft: question.ifReqField && question.ifReqField.length > 0 ? defaultSpaceLeftDependQuestion : '0',
      }}>
        <label
          htmlFor={fieldName + 'i'}
          className={`mt-1 questionHead ${question.ifReqField && question.ifReqField.length > 0 ? 'questionDepended' : ''}`}
        >
          {(!question.ifReqField || question.ifReqField.length === 0) && <span>{counter}. </span>}
          {question.question}
        </label>
        <div className='mt-1 questionOption'>
          <textarea
            id={fieldName + 'i'}
            name={fieldName}
            type='text'
            className="form-control"
            value={formik.values[fieldName] || ''}
            onChange={(e) => {
              formik.handleChange(e);
              formik.setFieldValue(fieldName, e.target.value);
            }}
            onBlur={formik.handleBlur}
          />
        </div>
        {formik.touched[fieldName] && formik.errors[fieldName] ? (
          <div className="error-message">{formik.errors[fieldName]}</div>
        ) : null}
      </div>
    </div>
  );
});

export default QuestionnaireRenderQuestionTextarea;