import React, { forwardRef } from 'react';
import { defaultSpaceBetweenQuestion, defaultSpaceLeftDependQuestion } from '../QuestionnaireCfg';
import { Form } from 'react-bootstrap';

const QuestionnaireRenderQuestionInputFile = forwardRef(({ index, question, formik, counter, prefix }, ref) => {

  const fieldName = question.idField;

  if (!question.show) return null;

  return (
    <div
      ref={ref}
      id={`mod-${question.idField}`}
      className={counter % 2 === 0 ? 'secondaryQuestion' : ''}
      style={{ paddingLeft: defaultSpaceLeftDependQuestion, paddingTop: defaultSpaceBetweenQuestion, paddingBottom: defaultSpaceBetweenQuestion }}
    >
      <div style={{
        paddingLeft: question.ifReqField && question.ifReqField.length > 0 ? defaultSpaceLeftDependQuestion : '0',
      }}>
        <label
          htmlFor={fieldName + 'i'}
          className={`mt-2 questionHead ${question.ifReqField ? 'questionDepended' : ''}`}
        >
          {!question.ifReqField && <span>{counter}. </span>}
          {question.question}
        </label>
        <Form.Control
          type='file'
          {...(question.cfg.quantity === "many" && { multiple: true })}
          name={fieldName}
          className="form-control mt-3"
          onChange={(event) => {
            const filesArray = Array.from(event.target.files);
            formik.setFieldValue(fieldName, filesArray);
          }}
          onBlur={formik.handleBlur}
          isInvalid={formik.touched[fieldName] && formik.errors[fieldName]}
        />
        {formik.touched[fieldName] && formik.errors[fieldName] ? (
          <div className="error-message">{formik.errors[fieldName]}</div>
        ) : null}
      </div>
    </div>
  );

});

export default QuestionnaireRenderQuestionInputFile;
