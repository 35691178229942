import appConfiguration from '../components/cfg/AppCfg';

const { apiUrl } = appConfiguration;


export const fetchQuestionnaireList = async () => {
  return fetchList(`${apiUrl}/v1/questionnaires`);
};

export const fetchQuestionnaireTemplateList = async () => {
  return fetchList(`${apiUrl}/v1/questionnaire-templates`);
};

export const fetchQuestionnaire = async (questionnaireId) => {
  return fetchDetails(`${apiUrl}/v1/questionnaires/${questionnaireId}`);
};

export const fetchQuestionnaireTemplate = async (templateId) => {
  return fetchDetails(`${apiUrl}/v1/questionnaire-templates/${templateId}`);
};

export const createQuestionnaire = async (questionnaire) => {
  return createOrUpdateItem(questionnaire, false, `${apiUrl}/v1/questionnaires`);
};

export const updateQuestionnaire = async (questionnaire) => {
  return createOrUpdateItem(questionnaire, true, `${apiUrl}/v1/questionnaires`);
};

const createOrUpdateItem = async (item, isUpdate, url) => {
  let errorMessage;
  try {
    const response = await fetch(url, {
      method: isUpdate ? "PATCH" : "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(item),
    });

    if (!response.ok) {
      switch (response.status) {
        case 400:
          errorMessage = "Bad Request - Invalid data provided.";
          break;
        case 401:
          errorMessage = "Unauthorized - Please log in.";
          break;
        case 403:
          errorMessage = "Forbidden - You don't have permission.";
          break;
        case 404:
          errorMessage = "Not Found - The requested resource was not found.";
          break;
        case 500:
          errorMessage = "Internal Server Error - Try again later.";
          break;
        default:
          errorMessage = `HTTP error! status: ${response.status}`;
      }
      throw new Error(errorMessage);
    }
    return await response.json();
  } catch (error) {
    console.error("Error saving item:", error.message);
    return {error : error.message}
  }
};


const fetchDetails = async (url) => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching item:", error);
  }
};

const fetchList = async (url) => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const json = await response.json();
    return json.items;
  } catch (error) {
    console.error("Error fetching the list:", error);
  }
};
