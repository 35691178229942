import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import appConfiguration from '../components/cfg/AppCfg';

const { userPool } = appConfiguration;

export function signIn(username, password) {
  return new Promise((resolve, reject) => {
    const authDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });

    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: userPool,
    });

    cognitoUser.authenticateUser(authDetails, {
      onSuccess: (session) => {
        resolve(session);
      },
      onFailure: (error) => {
        reject(error);
      },
    });
  });
}

export const signOut = () => {
  const cognitoUser = userPool.getCurrentUser();
  if (cognitoUser) {
    cognitoUser.signOut();
  }
};

export const getAuthenticatedUser = () => {
  return new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();

    if (!cognitoUser) {
      reject(new Error("No user found"));
      return;
    }

    cognitoUser.getSession((error, session) => {
      if (error) {
        reject(error);
        return;
      }
      cognitoUser.getUserAttributes((error, attributes) => {
        if (error) {
          reject(error);
          return;
        }
        const userData = attributes.reduce((acc, attribute) => {
          acc[attribute.Name] = attribute.Value;
          return acc;
        }, {});
        resolve({ ...userData, idToken: session.getIdToken().getJwtToken() });
      });
    });
  });
};

export function getSession() {
  const cognitoUser = userPool.getCurrentUser()
  return new Promise((resolve, reject) => {
    if (!cognitoUser) {
      reject(new Error("No user found"))
      return
    }

    cognitoUser.getSession((err, session) => {
      if (err) {
        reject(err)
        return
      }
      resolve(session)
    })
  })
}

export function getIdToken() {
  return getSession()
    .then((session) => session.getIdToken().getJwtToken())
    .catch((err) => null);

}