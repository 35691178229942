import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import FormModule from '../../cfg/ConstantStrings';


const QuestionnaireTypeQuestionDependentQuestions = ({ index, questions, setQuestions }) => {

  const { t } = useTranslation();
  const [availableOptions, setAvailableOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {

    const foundCfg = questions.filter(entry => entry.idField === questions[index].ifReqField);
    let variantCfgArray;

    if (foundCfg[0]) {
      if (foundCfg[0]?.cfg?.variantCfg) variantCfgArray = foundCfg[0]?.cfg?.variantCfg.map(item => item.variant);
      if (Array.isArray(variantCfgArray)) {
        const filteredArray = variantCfgArray.filter(item => !selectedOptions.includes(item));
        setAvailableOptions(filteredArray);
      }
    }

  }, [selectedOptions]);
  
  useEffect(() => {
    if (questions[index]?.ifReqValue) setSelectedOptions(questions[index]?.ifReqValue);
  }, []);

  const addDependentOption = (optionToAdd) => {

    const selectedTmp = [...selectedOptions, optionToAdd];
    setSelectedOptions(selectedTmp);

    const updatedQuestions = [...questions];
    updatedQuestions[index].ifReqValue = selectedTmp;
    setQuestions(updatedQuestions);
  }

  const deleteDependentOption = (optionToDelete) => {

    let selectedTmp = [...selectedOptions];
    selectedTmp = selectedTmp.filter(option => option !== optionToDelete);
    setSelectedOptions(selectedTmp);

    const updatedQuestions = [...questions];
    updatedQuestions[index].ifReqValue = selectedTmp;
    setQuestions(updatedQuestions);

  }

  const handleCfgChangeDependency = (event) => {

    const showMode = (event.target.value === "false" ? false : true);
    const updatedQuestions = [...questions];

    updatedQuestions[index].show = showMode;

    if (!updatedQuestions[index]?.ifReqValue) updatedQuestions[index].ifReqValue = [];

    if (showMode) {
      updatedQuestions[index].ifReqField = '';
      updatedQuestions[index].ifReqValue = [];
    }
    setQuestions(updatedQuestions);

  }

  const handleCfgChangeDependencyQuestion = (event) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].ifReqField = parseInt(event.target.value, 10);
    setQuestions(updatedQuestions);
    setSelectedOptions([]);
  }

  const dependencyFormHtml = () => {

    if (questions[index].show === false) {
      return (
        <>
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <div style={{ width: '150px' }}>{t('wybierz pytanie')}</div>
            <select
              onChange={(event) => handleCfgChangeDependencyQuestion(event)}
              value={questions[index].ifReqField}
              className="form-control"
            >
              <option></option>
              {questions.map((question) => {
                if ([FormModule.SELECT, FormModule.RADIO, FormModule.CHECKBOX].includes(question.type)) {
                  return (
                    <option
                      key={question.idField}
                      value={question.idField}>
                      {question.question}
                    </option>
                  );
                }
                return null;
              })}
            </select>
            <hr />
          </div>
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <div className="form-control" style={{ textAlign: 'center' }}>{t('dostępne warianty')}</div>
            <div className="form-control" style={{ textAlign: 'center' }}>{t('warianty aktywujące')}</div>
          </div>
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <select className="form-control" size={7}>
              {availableOptions.map(option => (
                <option
                  key={option}
                  onClick={() => addDependentOption(option)}
                >{option}
                </option>
              ))}
            </select>
            <select className="form-control" size={7}>
              {selectedOptions.map(option => (
                <option
                  key={option}
                  onClick={() => deleteDependentOption(option)}
                >{option}
                </option>
              ))}
            </select>
          </div>
        </>
      )
    }

  }

  return (
    <>
      <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <div style={{ width: '150px' }}>{t('pytanie zależne')}</div>
        <select
          onChange={(event) => handleCfgChangeDependency(event)}
          value={questions[index].show}
          className="form-control"
        >
          <option value="true">{t('nie')}</option>
          <option value="false">{t('tak')}</option>
        </select>
      </div>
      {dependencyFormHtml()}
    </>
  );
};

export default QuestionnaireTypeQuestionDependentQuestions;

