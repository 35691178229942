import { useLocation } from 'react-router-dom';
import { Container, Button } from 'react-bootstrap';

const BrakLinku = () => {
  
  const location = useLocation();

	const handleGoBack = () => {
		window.history.back();
	};  

  return (
    <Container>
      <h3>Brak linku</h3>
      <p>Nie znaleziono strony dla ścieżki: {location.pathname}</p>
      <Button onClick={handleGoBack}>Powrót</Button>
    </Container>
  );
};

export default BrakLinku;
