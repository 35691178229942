import React, { useState, useEffect, forwardRef } from 'react';
import { defaultSpaceBetweenQuestion, defaultSpaceLeftDependQuestion } from '../QuestionnaireCfg';
import { Form } from 'react-bootstrap';

const QuestionnaireRenderQuestionInputPictures = forwardRef(({ index, question, formik, counter, prefix, setShowDialog, setDialogProps }, ref) => {

  const fieldName = question.idField;
  const [images, setImages] = useState([]);

  useEffect(() => {
    const newImages = [...images];
    for (let index = 0; index < question.cfg.maxQuantity; index++) {
      newImages[index] = formik.values[`${fieldName}_${index}`];
    }
    setImages(newImages);
  }, [formik.values]);

  if (!question.show) return null;

  const clearPicture = (indexToRemove) => {
    const newImages = [...images];
    newImages[indexToRemove] = null;
    setImages(newImages);
    clearFileInput(`${fieldName}_${indexToRemove}`);
    formik.setFieldValue(`${fieldName}_${indexToRemove}`, '');
  };

  const clearFileInput = (fieldToClear) => {
    const input = document.querySelector(`input[name="${fieldToClear}"]`);
    if (input) {
      input.value = null;
    }
  };

  const renderFormList = () => {
    const items = [];
    for (let index = 0; index < question.cfg.maxQuantity; index++) {
      const fieldNameIndex = `${fieldName}_${index}`;
      if (formik.values[`${fieldName}_${index}`].length === 0) {
        items.push(
          <div key={index} className='mt-2 questionOption'>
            <Form.Control
              type='file'
              accept="image/*"
              name={fieldNameIndex}
              className="form-control mt-3"
              onChange={handleImageChange(index)}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched[fieldNameIndex] && formik.errors[fieldNameIndex]}
            />
          </div>
        );
      }
    }
    items.push(
      <div key={question.cfg.maxQuantity} className='mt-2 questionOption'>
        {formik.touched[`${fieldName}_0`] && formik.errors[`${fieldName}_0`] ? (
          <div className="error-message">{formik.errors[`${fieldName}_0`]}</div>
        ) : null}
      </div>
    )
    return items;
  }

  const renderPictures = () => {
    const items = [];
    for (let index = 0; index < question.cfg.maxQuantity; index++) {
      if (images[index]) {
        const imgId = `${fieldName}_${index}_pic`;
        items.push(
          <div key={index} className='mx-3' style={{ position: 'relative', display: 'inline-block' }}>
            <img id={imgId} src={images[index]} style={{ maxWidth: '320px', display: 'block' }} />
            <hr />
            <button
              type="button"
              onClick={() => clearPicture(index)}
              className="btn btn-secondary"
              style={{ position: 'absolute', bottom: '0', left: '0', width: '100%' }}
            >
              <i className="bi bi-trash"></i>
            </button>
          </div>
        )
      }
    }
    return items;
  }

  const handleImageChange = (index) => (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      if (file.type && file.type.startsWith('image/')) {
        const fileContent = e.target.result;
        if (Object.values(formik.values).includes(fileContent)) {
          event.target.value = null;
          setDialogProps({
            title: 'Uwaga!!',
            contentTxt: 'Zdublowany kontent.',
            buttons: [
              { text: 'zamknij', variant: 'primary', onClick: () => setShowDialog(false) }
            ],
          });
          setShowDialog(true);
        } else {
          const newImages = [...images];
          newImages[index] = fileContent;
          setImages(newImages);
          formik.setFieldValue(`${fieldName}_${index}`, fileContent);
        }
      } else {
        event.target.value = null;
        setDialogProps({
          title: 'Uwaga!!',
          contentTxt: 'To nie jest zdjęcie.',
          buttons: [
            { text: 'zamknij', variant: 'primary', onClick: () => setShowDialog(false) }
          ],
        });
        setShowDialog(true);
      }
    };
    reader.readAsDataURL(file);
  };

  return (
    <div
      ref={ref}
      id={`mod-${question.idField}`}
      className={counter % 2 === 0 ? 'secondaryQuestion' : ''}
      style={{ paddingLeft: defaultSpaceLeftDependQuestion, paddingTop: defaultSpaceBetweenQuestion, paddingBottom: defaultSpaceBetweenQuestion }}
    >
      <div style={{
        paddingLeft: question.ifReqField && question.ifReqField.length > 0 ? defaultSpaceLeftDependQuestion : '0',
      }}>
        <label
          htmlFor={fieldName + 'i'}
          className={`mt-2 questionHead ${question.ifReqField ? 'questionDepended' : ''}`}
        >
          {!question.ifReqField && <span>{counter}. </span>}
          {question.question}
        </label>
        {renderFormList()}
        <hr />
        {renderPictures()}
        <hr />
      </div>
    </div>
  );

});

export default QuestionnaireRenderQuestionInputPictures;