import { Button, Container } from 'react-bootstrap';
import UserContext from '../context/UserContext';
import React, { useContext } from 'react';
import appConfiguration from '../cfg/AppCfg';
import { Navigate } from 'react-router-dom';

const UserChange = () => {

  const { handleChangeUserTmp, user } = useContext(UserContext);

  if (!user.admin) {
    return <Navigate to="/konsola/katalogankiet/" />;
  }

  const handleChcangeUser = async (userChange) => {

    try {
      const response = await fetch(appConfiguration.loginLinkPost, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username: userChange })
      });

      if (!response.ok) {
        throw new Error('Błak połączenia z serwerem.');
      }

      const responseData = await response.json();
      const success = responseData.success;

      if (success) {
        handleChangeUserTmp(responseData.received_data);
      }

    } catch (error) {
      alert(error.message);
    }

  };

  return (
    <Container>
      <h3>Podmiana użytkownika</h3>
      <ul style={{ listStyleType: 'none' }}>
        <li><Button className="btn form-control mt-4" onClick={() => handleChcangeUser('test')}>test</Button></li>
        <li><Button className="btn form-control mt-4" onClick={() => handleChcangeUser('tests')}>tests</Button></li>
        <li><Button className="btn form-control mt-4" onClick={() => handleChcangeUser('test1')}>test1</Button></li>
      </ul>
      {JSON.stringify(user)}
    </Container>
  );
};

export default UserChange;
