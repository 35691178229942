import React, { useState, useEffect } from 'react';
import DataTable from '../base/DataTableBase';
import { useUser } from '../../../context/UserContext';
import { Navigate } from 'react-router-dom';

import columns from './DataTableColumns/DTTcolumns2';
import conditionalRowStyles from './DataTableRowStyles/DTTstyles2';

function DataTableBaseImportProduktyKolorowanka() {

    const { user } = useUser();
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
				const response = await fetch('/json/produkty.json');
                const json = await response.json();
                setData(json.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    if (!user) {
        return <Navigate to="/" />;
    }

	const ExpandedComponent = ({ data }) => {
        return  <pre>{JSON.stringify(data, null, 2)}</pre>
    };

    return (
        <DataTable
            columns={columns}
            data={data}
            selectableRows
			expandableRows
			expandableRowsComponent={ExpandedComponent}
            conditionalRowStyles={conditionalRowStyles}
            returnButton={true}
        />
    );
}

export default DataTableBaseImportProduktyKolorowanka;
