import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const Dialog = ({ title, contentTxt, show, buttons, dialogContentComponent: DialogContentComponent, dialogSize, dialogClassName }) => {
  const dialogSizeWidth = dialogSize || 50;

  const createMarkup = (htmlString) => {
    return { __html: htmlString };
  };

  const uniqueClassName = dialogClassName ? dialogClassName : `modal-size-${Math.random().toString(36).substr(2, 9)}`;

  const modalStyle = `
  .${uniqueClassName} {
    max-width: none !important;
    width: ${dialogSizeWidth}%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .${uniqueClassName} .modal-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal-body {
    max-height: calc(100vh - 150px);
    overflow-y: auto;
  }
  `;

  return (
    <>
      <style>{modalStyle}</style>
      <Modal show={show} dialogClassName={uniqueClassName} centered>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div dangerouslySetInnerHTML={createMarkup(contentTxt)} />
          {DialogContentComponent ? <DialogContentComponent /> : null}
        </Modal.Body>
        <Modal.Footer>
          {buttons.map((button, index) => (
            <Button key={index} variant={button.variant} onClick={button.onClick}>
              {button.text}
            </Button>
          ))}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Dialog;