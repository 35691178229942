import fetchIntercept from 'fetch-intercept';
import appConfiguration from '../components/cfg/AppCfg';

export const AuthInterceptor = (idToken) => {
  return fetchIntercept.register({
    request: function (url, config) {
      if (url.startsWith(appConfiguration.apiUrl)) {
        if (!config) {
          config = {};
        }
        config = {
          ...config,
          headers: {
            ...config.headers,
            'X-API-key': idToken,
          },
        };
      }
      return [url, config];
    },

    requestError: function (error) {
      // Called when an error occured during another 'request' interceptor call
      return Promise.reject(error);
    },

    response: function (response) {
      // Modify the reponse object
      return response;
    },

    responseError: function (error) {
      // Handle an fetch error
      return Promise.reject(error);
    },
  });
};