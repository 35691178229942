import React, { useState, useEffect } from 'react';
import { Button, Container, Row, Col, Form, Alert } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import * as Yup from "yup";
import { Formik } from "formik";

const ProductsGroup = () => {

    const [firstEffectExecuted, setFirstEffectExecuted] = useState(false);
    const [initialValues, setInitialValues] = useState({
        listName: '',
    });
    const [variants, setVariants] = useState([]);
    const [productsCatalog, setProductsCatalog] = useState([]);
    const [productsSelect, setProductsSelect] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [zawezaj, setZawezaj] = useState('');
    const [listName, setlistName] = useState('');

    const { id } = useParams();
    const navigate = useNavigate();

    let infoContent = id === '0' ? 'Nowa lista' : 'Edycja';

    const validationSchema = Yup.object({
        listName: Yup.string().required('Nazwa dla listy jest wymagana')
    });


    useEffect(() => {
        let isMounted = true;

        fetch(`/json/products.json`)
            .then(response => response.json())
            .then(data => {
                if (isMounted) {
                    setProductsCatalog(data);
                    setProductsSelect(data);
                    setFirstEffectExecuted(true);
                }
            })
            .catch(error => {
                if (isMounted) {
                    alert('Błąd podczas odbierania danych');
                    navigate("/konsola/");
                }
            });

        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        let isMounted = true;

        if (firstEffectExecuted && (id > 0 || !id)) {
            fetch(`/json/listaProduktow_${id}.json`)
                .then(response => response.json())
                .then(data => {
                    if (isMounted) {
                        setlistName(data["Nazwa"]);
                        let loadCfg = [];
                        for (const idProduct of data["idProduct"]) {
                            const filteredProductsSelect = productsCatalog.filter(product => idProduct === product.idProduct);
                            loadCfg.push(...filteredProductsSelect);
                        }
                        setVariants(loadCfg);
                        setInitialValues({
                            listName: data["Nazwa"] || '',
                        });
                        console.table(loadCfg);
                    }
                })
                .catch(error => {
                    if (isMounted) {
                        alert('Błąd podczas odbierania danych');
                        navigate("/konsola/");
                    }
                });
        }
        return () => {
            isMounted = false;
        };
    }, [firstEffectExecuted, id, productsCatalog, navigate]);

    useEffect(() => {
        const filteredProductsSelect = productsCatalog.filter(product => !variants.some(variant => variant.idProduct === product.idProduct));

        const matchingProducts = filteredProductsSelect.filter(product => {
            return (
                product.Produkt.includes(zawezaj) ||
                product.EAN.toString().includes(zawezaj) ||
                product.KOD.includes(zawezaj)
            );
        });

        setProductsSelect(matchingProducts);

    }, [zawezaj, variants]);

    useEffect(() => {
        const firstProduct = productsSelect.find(product => true);
        setSelectedProduct(firstProduct);
    }, [productsSelect]);

    const handleSelectChange = (e) => {
        const selectedProductId = parseInt(e.target.value);
        const product = productsCatalog.find(p => p.idProduct === selectedProductId);
        setSelectedProduct(product);
    };

    const handleAddVariant = () => {
        if (selectedProduct) {
            setVariants([...variants, selectedProduct]);
        } else {
            alert('Proszę wybrać produkt z listy.');
        }
    };

    const onSubmit = async (values, { resetForm }) => {

        let formCfg = {};
        formCfg.idListy = id;
        formCfg.idProduct = variants.map(product => product.idProduct);
        formCfg.Nazwa = values.listName;
        console.table(formCfg);

        try {
            const response = await fetch('http://data.pl/klient', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formCfg)
            });
            if (!response.ok) {
                throw new Error('Wystąpił problem podczas wysyłania danych.');
            }
            navigate("/konsola/klienci/lista");
        } catch (error) {
            console.error('Błąd podczas wysyłania danych:', error);
            //alert('Wystąpił problem podczas wysyłania danych.');
        }
    }

    const deleteVariant = (index) => {
        const newVariants = [...variants];
        newVariants.splice(index, 1);
        setVariants(newVariants);
    };

    const moveDownVariant = (index) => {
        const newVariants = [...variants];
        let temp = newVariants[index + 1];
        newVariants[index + 1] = newVariants[index];
        newVariants[index] = temp;
        setVariants(newVariants);
    };
    const moveUpVariant = (index) => {
        const newVariants = [...variants];
        let temp = newVariants[index - 1];
        newVariants[index - 1] = newVariants[index];
        newVariants[index] = temp;
        setVariants(newVariants);
    };

    return (
        <Container style={{ border: '1px solid #ccc', padding: '20px' }}>
            <Alert variant="info" className="text-center mb-3"><b>{infoContent}</b></Alert>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize={true}
                onSubmit={onSubmit}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit
                }) => (
                    <Form onSubmit={handleSubmit}>
                        <div className="input-group mb-3">
                            <div style={{ width: '80px' }}>Nazwa listy</div>

                            <Form.Control
                                type="text"
                                name="listName"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.listName}
                                isInvalid={touched.listName && !!errors.listName}
                            />
                            {touched.listName && errors.listName && (
                                <Form.Control.Feedback type="invalid">
                                    {errors.listName}
                                </Form.Control.Feedback>
                            )}

                        </div>
                        <font className="input-group mb-3" style={{ fontSize: '80%' }}>
                            Wybrano : {variants.length}
                        </font>
                        <div className="input-group mb-3">
                            {variants.map((variant, index) => (
                                <div key={index} className="input-group mb-3">
                                    <div style={{ width: '80px' }}>produkt</div>
                                    <input
                                        id={`variant-${index}`}
                                        type="text"
                                        disabled={true}
                                        value={variant.Produkt || ''}
                                        className="form-control"
                                    />

                                    <div className="input-group-append">

                                        <button
                                            type="button"
                                            onClick={() => deleteVariant(index)}
                                            className="btn btn-outline-secondary"
                                        >
                                            <i className="bi bi-trash"></i>
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => moveUpVariant(index)}
                                            disabled={index === 0}
                                            className="btn btn-outline-secondary"
                                        >
                                            <i className="bi bi-arrow-up"></i>
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => moveDownVariant(index)}
                                            disabled={index === variants.length - 1}
                                            className="btn btn-outline-secondary"
                                        >
                                            <i className="bi bi-arrow-down"></i>
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <font style={{ fontSize: '80%' }}>
                            Wybierz produkt
                        </font>
                        <select
                            onChange={handleSelectChange}
                            className="form-control"
                        >
                            {productsSelect.map(option => (
                                <option key={option.idProduct} value={option.idProduct}>{`${option.Produkt}`}</option>
                            ))}
                        </select>
                        <input
                            type="text"
                            placeholder='Zawężaj po frazie'
                            className="form-control"
                            onChange={(e) => setZawezaj(e.target.value)}
                        />
                        <Button type="button" onClick={handleAddVariant} className="btn btn-primary w-100 mt-2">
                            dodaj produkt do listy
                        </Button>
                        <Button variant="primary" type="submit" className="w-100 mt-2">
                            zapisz listę
                        </Button>
                    </Form>
                )}
            </Formik>
        </Container>
    );

};

export default ProductsGroup;
