const FormModule = {
  INPUT: 'input',
  RADIO: 'radio',
  CHECKBOX: 'checkbox',
  INPUT_FILE: 'inputFile',
  TEXTAREA: 'textarea',
  INFOBLOCK: 'infoblok',
  CROSSPROD: 'crossprod',
  SELECT: 'select',
  BLOCKTEMPLATE: 'blockTemplate',
  DATEHOUR: 'datehour',
  PICTURES: 'pictures'
};

export default FormModule;