import React, { useState, useEffect } from 'react';
import { Button, Form, Alert, Container } from 'react-bootstrap';
import { useUser } from "../context/UserContext";
import { useTranslation } from "react-i18next";

const PasswordChange = () => {
  const { onPasswordChange } = useUser();
  const { t } = useTranslation();

  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [error, setError] = useState('');
  const [response, setResponse] = useState('');
  const [changeDisabled, setChangeDisabled] = useState(true);

  useEffect(() => {
    if (password1 !== password2) {
      setError(t('"Nowe hasło" i "Powtórz nowe hasło" nie są takie same.'));
      setResponse('');
      setChangeDisabled(true);
    } else {
      if (password1.length > 5) {
        setResponse(t('Można zmienić hasło.'));
        setChangeDisabled(false);
      }
      setError('');
    }
  }, [password1, password2]);

  const handlePasswordChange = async () => {
    try {
      await onPasswordChange(password1);
      setResponse(t('Hasło zostało zmienione.'));
      setError('');
    } catch (error) {
        setResponse('');
        setError(t('Nie udało się zmienić hasła.'));
        console.log(error.message);
    }
  }

  return (
    <Container>
      <h2>{t('Zmiana hasła')}</h2>
      <Form>
        <Form.Group controlId="formUsername">
          <Form.Label>{t('Nowe hasło')}:</Form.Label>
          <Form.Control type="password" value={password1} onChange={(e) => setPassword1(e.target.value)} />
        </Form.Group>
        <Form.Group controlId="formHaslo">
          <Form.Label>{t('Powtórz nowe hasło')}:</Form.Label>
          <Form.Control type="password" value={password2} onChange={(e) => setPassword2(e.target.value)} />
        </Form.Group>
        <Form.Group controlId="formButton" className="d-flex justify-content-between">
          <Button variant="primary" type="button" disabled={changeDisabled} onClick={handlePasswordChange} className="mt-2">
            {t('Ustaw hasło')}
          </Button>
        </Form.Group>
      </Form>
      <br />
      {error && <Alert variant="danger">{error}</Alert>}
      {response && <Alert variant="success">{response}</Alert>}
    </Container>
  );
};

export default PasswordChange;
