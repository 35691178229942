import React, {forwardRef } from 'react';
import { defaultSpaceBetweenQuestion, defaultSpaceLeftDependQuestion } from '../QuestionnaireCfg';
import QuestionnaireRenderQuestionShowOrHide from './QuestionnaireRenderQuestionShowOrHide';
import { Form } from 'react-bootstrap';

const QuestionnaireRenderQuestionRadio = forwardRef(({ index, question, questions, formik, counter, prefix }, ref) => {

  const fieldName = question.idField;

  const handleChangeForm = (event) => {

    QuestionnaireRenderQuestionShowOrHide({
      event,
      question,
      questions,
      formik,
    });

    formik.handleChange(event);

  }

  const radioInput = (variant, idx) => {

    const radioValue = variant.variant;
    const isChecked = formik.values[fieldName] === radioValue;

    let divStyle = null;

    if (question.cfg.visualization === 'horizontal') {
      divStyle = { display: 'inline-block', marginRight: '0.5cm' };
    }

    return (
      <div key={idx}
        className={question.cfg.visualization === 'vertical' ? "mb-2" : "mt-1"}
        style={divStyle}
      >
        <Form.Check
          type='radio'
          id={`question-${index}-${idx}`}
          label={variant.variant}
          name={fieldName}
          value={radioValue}
          checked={isChecked}
          onChange={handleChangeForm}
          onBlur={formik.handleBlur}
          className="me-2 or mr-2"
          isInvalid={formik.touched[fieldName] && formik.errors[fieldName]}
        />
      </div>
    );
  };

  if (!question.show) return null;

  return (
    <div
      ref={ref}
      id={`mod-${question.idField}`}
      className={counter % 2 === 0 ? 'secondaryQuestion' : ''}
      style={{ paddingLeft: defaultSpaceLeftDependQuestion, paddingTop: defaultSpaceBetweenQuestion, paddingBottom: defaultSpaceBetweenQuestion }}
    >
      <div style={{
        paddingLeft: question.ifReqField && question.ifReqField.length > 0 ? defaultSpaceLeftDependQuestion : '0',
      }}>
        <label htmlFor={fieldName + 'i'} className="mt-2 questionHead">{(!question.ifReqField || question.ifReqField.lenght === 0) && <span>{counter}. </span>}{question.question}</label>
        <div className='mt-1 questionOption'>
          {question.cfg.variantCfg.map((variant, idx) => radioInput(variant, idx))}
          {formik.touched[fieldName] && formik.errors[fieldName] ? (
            <div className="error-message">{formik.errors[fieldName]}</div>
          ) : null}
        </div>
      </div>
    </div>
  );

});

export default QuestionnaireRenderQuestionRadio;