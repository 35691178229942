import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectWrapperJson from '../../common/SelectWrapperJson';
import DictionaryContext from '../../context/DictionaryContext';
import Dialog from '../../common/Dialog';
import Spinner from '../../common/Spinner';
import QuestionnaireRender from '../QuestionnaireRender';
import { fetchQuestionnaireTemplate } from '../../../services/questionnaire';
import { Row, Col, FormControl, Form, Button } from 'react-bootstrap';

const QuestionnaireTypeQuestionBlockTemplate = ({ index, questions, setQuestions, formik }) => {
  const { t } = useTranslation();
  const { dictionaryTemplates } = useContext(DictionaryContext);
  const [dictionaryTemplatesFiltered, setDictionaryTemplatesFiltered] = useState(dictionaryTemplates);

  const [dialogProps, setDialogProps] = useState({ buttons: [] });
  const [showDialog, setShowDialog] = useState(false);

  const [templateId, setTemplateId] = useState(questions[index]?.cfg?.templateId || '');
  const [templateButtonPrev, setTemplateButtonPrev] = useState(true);

  const [showSpinner, setShowSpinner] = useState(false);

  const formikField = `question-${questions[index].idField}-templateId`;

  useEffect(() => {
    setTemplateButtonPrev(templateId === '');
  }, [templateId]);

  const handleCfgChange = (event) => {
    const updatedQuestions = [...questions];
    const newTemplateId = event.target.value;
    updatedQuestions[index]['cfg']['templateId'] = newTemplateId;
    formik.setFieldValue(formikField, newTemplateId);
    setQuestions(updatedQuestions);
    setTemplateId(newTemplateId);
  };

  const handleFilterChange = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    const selectedTemplateId = questions[index]?.cfg?.templateId;

    const selectedTemplate = dictionaryTemplates.find(template => template.id === selectedTemplateId);

    const filteredTemplates = dictionaryTemplates.filter(template =>
    (template.name.toLowerCase().includes(searchTerm) ||
      template.description.toLowerCase().includes(searchTerm))
    );

    if (selectedTemplate && !filteredTemplates.some(template => template.id === selectedTemplateId)) {
      setDictionaryTemplatesFiltered([selectedTemplate, ...filteredTemplates]);
    } else {
      setDictionaryTemplatesFiltered(filteredTemplates);
    }
  };

  const handleClose = () => setShowDialog(false);

  const handleViewTemplate = async () => {

    setShowSpinner(true);

    const data = await fetchQuestionnaireTemplate(templateId);

    setShowSpinner(false);

    setDialogProps({
      title: t('Podgląd szablonu'),
      dialogSize: '98',
      buttons: [
        { text: t('zamknij'), variant: 'secondary', onClick: handleClose }
      ],
      dialogContentComponent: () => (
        <QuestionnaireRender
          questionnaire={{ id: 0, name: '', description: '' }}
          questions={data.questionsCfg}
        />
      )
    });
    setShowDialog(true);
  }

  return (
    <>
      <Dialog
        title={dialogProps.title}
        contentTxt={dialogProps.contentTxt}
        show={showDialog}
        buttons={dialogProps.buttons}
        dialogContentComponent={dialogProps.dialogContentComponent}
        dialogSize={dialogProps.dialogSize}
        dialogClassName="unique-modal-QuestionnaireTypeQuestionCrossProducts"
      />
      <Row>
        <Col className="d-flex flex-column justify-content-center mt-2">
          {t('szablon')}
        </Col>
        <Col md={11} className="d-flex flex-column justify-content-center mt-2">
          <select
            onChange={(event) => handleCfgChange(event)}
            className="form-control"
            size="5"
          >
            <SelectWrapperJson
              dataSet={dictionaryTemplatesFiltered}
              fieldOptionText='name'
              fieldOptionValue='id'
              defaultSelected={templateId}
            />
          </select>
          <Col className="text-danger fs-6">{formik.errors[formikField]}</Col>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex flex-column justify-content-center mt-2 mb-2">
          {t('szukaj')}
        </Col>
        <Col md={9} className="d-flex flex-column justify-content-center mt-2 mb-2">
          <Form.Control
            type="text"
            className="form-control"
            onChange={handleFilterChange}
          />
        </Col>
        <Col md={2} className="d-flex flex-column justify-content-center mt-2 mb-2">
          <Button
            type="button"
            disabled={templateButtonPrev}
            onClick={handleViewTemplate}
          >
            {t("podgląd")}
          </Button>
        </Col>
      </Row>
      {showSpinner && (<Spinner />)}
    </>
  );
};

export default QuestionnaireTypeQuestionBlockTemplate;
