import React from 'react';
import { useTranslation } from 'react-i18next';
import QuestionnaireTypeQuestionDependentQuestions from './QuestionnaireTypeQuestionDependentQuestions';

const QuestionnaireTypeQuestionInputDateHour = ({ index, questions, setQuestions }) => {

  const { t } = useTranslation();

  const handleCfgChange = (event, field) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index]['cfg'][field] = event.target.value;
    setQuestions(updatedQuestions);
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <div style={{ width: '120px' }}>{t('Format')}</div>
        <select
          onChange={(event) => handleCfgChange(event, 'type')}
          value={questions[index]?.cfg?.type}
          className="form-control"
        >
          <option value="datehour">{t('data i godziny')}</option>
          <option value="date">{t('data')}</option>
        </select>
      </div>
      <QuestionnaireTypeQuestionDependentQuestions index={index} questions={questions} setQuestions={setQuestions} />
    </div>
  );
};

export default QuestionnaireTypeQuestionInputDateHour;