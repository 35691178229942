import React, { useState, useEffect } from 'react';
import DataTableBasejQuery from '../../datatable/table/base/DataTableBasejQuery';
import ClientListColumns from './ProductsGroupColumns';
import { useNavigate } from 'react-router-dom';

function ProductsGroupList() {
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('/json/productsGroupList.json');
                const json = await response.json();
                setData(json);
            } catch (error) {
                console.error('Błąd pobierania danych:', error);
            }
        };

        fetchData();
        setColumns(ClientListColumns);
    }, []);

    useEffect(() => {

        const handleClick = event => {
            const element = event.target.closest('.jqueryDataTable-link');
            if (element) {
                event.preventDefault();
                const path = element.getAttribute('data-href');
                navigate(path);
            }
        };

        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [navigate]);

    const handleNowaLista = () => {
        navigate('/konsola/produkty/listaGrupa/0');
    }

    const aLengthMenu = [
        [10, 20, -1],
        ['Mała', 'średnia', 'wszystko']
    ]

    const columnDefs = [
        {
            visible: false,
            targets: [0]
        },
    ]

    return (
        <div className='shadow'>
            <div className="d-flex justify-content-center mb-4">
                <button className="btn btn-outline-primary form-control" onClick={() => handleNowaLista()}>dodaj nową listę</button>
            </div>
            {data.length > 0 && columns.length && (
                <DataTableBasejQuery
                    columns={columns}
                    data={data}
                    showFooter={false}
                    aLengthMenu={aLengthMenu}
                    columnDefs={columnDefs}
                    localName='ProductsGroupList'
                />
            )
            }
        </div>
    );
}

export default ProductsGroupList;
