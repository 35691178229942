import React, { useEffect, useRef } from 'react';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-fixedheader';
import 'datatables.net-colreorder';
import 'datatables.net-buttons';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';

import 'datatables.net-buttons-bs5';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import 'datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css';
import { Container } from 'react-bootstrap';

import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const DataTableBasejQuery = ({
  columns,
  data,
  dom = "<'row justify-content-center'<'col-3 text-start'l><'col-6 text-center'B><'col-3 text-start'f>> " +
  "<'row mt-4'<'col-12'tr>>" +
  "<'row mt-3 justify-content-center'<'col-auto'p>>" +
  "<'row mt-3 justify-content-end'<'col-auto'i>>",
  buttons = [{
    extend: 'copyHtml5',
    text: 'kopiuj',
    titleAttr: 'Kopiuj rekordy do schowka',
    header: true,
    footer: false,
    exportOptions: {
      columns: ':visible'
    }
  }, {
    extend: 'excelHtml5',
    text: 'zapisz jako Excel',
    exportOptions: {
      columns: ':visible'
    }
  }, {
    extend: 'csvHtml5',
    text: 'zapisz CSV',
    titleAttr: 'Zapisz tabele jako dokument CSV',
    exportOptions: {
      columns: ':visible'
    },
    charset: 'UTF-8',
    bom: true,
    fieldSeparator: ';',
    fieldBoundary: '"',
    escapeChar: '"',
    newLine: '\r\n',
    header: true,
    footer: false,
  }, {
    extend: 'pdfHtml5',
    text: 'zapisz PDF',
    exportOptions: {
      columns: ':visible'
    }
  },
  {
    extend: 'colvis',
    text: 'widok',
    titleAttr: 'Wybierz kolumny do wyświetlenia',
    columns: ':not(.noVis)',
  },
  {
    extend: 'print',
    text: 'wydrukuj',
    titleAttr: 'Wydrukuj tabele',
    exportOptions: {
      columns: ':visible'
    }
  }],
  aLengthMenu = [
    [10, 20, -1],
    ['mała', 'średnia', 'wszystko']
  ],
  columnDefs = [],
  showFooter = true,
  localName = ''
}) => {

  const tableRef = useRef(null);
  let dataTable = null;

  useEffect(() => {

    if (tableRef.current && !$.fn.DataTable.isDataTable(tableRef.current)) {
      $(tableRef.current).DataTable({
        data: data,
        colReorder: true,
        colResize: true,
        stateSave: true,
        columns: columns,
        fixedHeader: {
          header: true,
          footer: showFooter,
        },
        dom: dom,
        "language": {
          "lengthMenu": "Wyświetl&nbsp;_MENU_",
          "search": "Wyszukaj&nbsp;",
          "info": "Wyświetlono _START_ do _END_ z _TOTAL_ wpisów",
          "infoEmpty": "Wyświetlono 0 z 0 wpisów",
          "emptyTable": "Brak danych",
          "paginate": {
            "first": "Pierwsza",
            "last": "Ostatnia",
            "next": "Następna",
            "previous": "Poprzednia"
          }
        },
        buttons: buttons,
        aLengthMenu: aLengthMenu,
        columnDefs: columnDefs,
        initComplete: function () {

          this.api().columns().every(function () {
            var column = this;
            $(column.header(1)).empty();
          });

          const savedState = this.api().state.loaded();

          const getValueFromState = (column, savedState) => {
            let searchValue = '';
            if (savedState !== null && savedState.colReorder !== undefined) {
              let columnIndexWithColReorder = null;
              savedState.colReorder.forEach(function (value, index) {
                if (value === column.index()) {
                  columnIndexWithColReorder = index;
                  if (savedState.columns && savedState.columns[column.index()]) {
                    searchValue = savedState.columns[columnIndexWithColReorder].search.search;
                  }
                }
              })
            }
            return {
              columnIndex: column.index(),
              columnName: column.header(0).textContent.trim(),
              searchValue: searchValue
            }
          }

          this.api().columns(".filterbyinput").every(function () {
            let column = this;
            const { columnIndex, columnName, searchValue } = getValueFromState(column, savedState);
            $('<input type="text" class="form-control w-100" placeholder="Szukaj..." value="' + searchValue + '" />')
              .appendTo($(column.header(1)))
              .on('keyup change clear', function () {
                column.table().columns(':contains("' + columnName + '")').search(this.value).draw();
              });
          });

          this.api().columns(".filterbyselect").every(function () {

            let column = this;
            const { columnIndex, columnName, searchValue } = getValueFromState(column, savedState);
            var select = $('<select class="form-control w-100"><option value=""></option></select>')
              .appendTo($(column.header(1)))
              .on('change', function () {
                var val = $.fn.dataTable.util.escapeRegex(this.value);
                var regex = new RegExp('^' + val, 'i');
                column.table().columns(':contains("' + columnName + '")').search(this.value).draw();
              });

            column.data().unique().sort().each(function (d, j) {
              if (d !== null) {
                let option = $('<option value="' + d + '">' + d + '</option>');
                if (d.toString() === searchValue.toString()) {
                  option.prop('selected', true);
                }
                select.append(option);
              }
            });

          });

        }
      });
      dataTable = $(tableRef.current).DataTable();

    }

    return () => {
      if (dataTable) {
        dataTable.destroy();
      }
    };

  }, [data, localName]);

  return (
    <Container fluid>
      <table ref={tableRef} id={localName} className="table table-striped table-bordered w-100">
        <thead style={{ backgroundColor: '#fff', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
          <tr>
            {columns.map((column, index) => (
              <th key={index} className={column.class}></th>
            ))}
          </tr>
          <tr>
            {columns.map((column, index) => (
              <th key={index} data-dt-order="disable"></th>
            ))}
          </tr>
        </thead>
        <tbody>
        </tbody>
        {
          showFooter && (
            <tfoot style={{ backgroundColor: '#fff', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
              <tr>
                {columns.map((column, index) => (
                  <th key={index}>{index}</th>
                ))}
              </tr>
            </tfoot>
          )
        }
      </table>
    </Container>
  );
};

export default DataTableBasejQuery;