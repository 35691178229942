const columns = [
    {
        "data": "idProduct",
        "class": "noVis"            
    },
    {
        "title": "Produkt",
        "data": "Produkt",
        "class": "filterbyinput",
        "render": function(data, type, full, meta) {
            return `<a class="jqueryDataTable-link" data-href="/konsola/produkty/edycja/${full.idProduct}">${data}</a>`;
        }
    },
    {
        "title": "Klient",
        "data": "Klient",
        "class": "filterbyselect"            
    },
    {
        "title": "Grupa",
        "data": "Grupa",
        "class": "filterbyselect"  
    },  
    {
        "title": "Brand",
        "data": "Brand",
        "class": "filterbyselect"  
    },
    {
        "title": "EAN",
        "data": "EAN",
        "class": "filterbyinput"  
    },
    {
        "title": "KOD",
        "data": "KOD",
        "class": "filterbyinput"  
    }

];

export default columns;
