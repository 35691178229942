const columns = [
    {
        "data": "IdFirmy",
        "class": "noVis"            
    },
    {
        "title": "Klient",
        "data": "NazwaFirmy",
        "class": "filterbyinput",
        "render": function(data, type, full, meta) {
            //return `<a class="jqueryDataTable-link" data-href="/konsola/klienci/edycja/${full.IdFirmy}">${data}</a>`;
            return `<a class="jqueryDataTable-link" data-href="/konsola/klienci/edycja/19376">${data}</a>`;
        }
    },
    {
        "title": "Miasto",
        "data": "Miasto",
        "class": "filterbyselect"            
    },
    {
        "title": "Kod Pocztowy",
        "data": "KodPocztowy",
        "class": "filterbyinput"  
    },  
    {
        "title": "Adres",
        "data": "Adres",
        "class": "filterbyinput"  
    },
    {
        "title": "NIP",
        "data": "NIP",
        "class": "filterbyinput"  
    },

    {
        "title": "PM",
        "data": "Email",
        "class": "filterbyinput"  
    }

];

export default columns;
