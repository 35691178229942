import React, { useState, useEffect } from 'react';
import DataTable from './base/DataTableBase';
import { createTheme } from 'react-data-table-component';

import columns from './columns/DataTableAnkietyColumns';
import conditionalRowStyles from './rowStyles/DataTableAnkietyRowStyles';

function DataTableAnkiety() {

  const [data, setData] = useState([]);
  const [progressPending, setProgressPending] = useState(true);

  createTheme('solarized', {
    text: {
      primary: '#268bd2',
      secondary: '#2aa198',
    },
    background: {
      default: '#002b36',
    },
    context: {
      background: '#cb4b16',
      text: '#FFFFFF',
    },
    divider: {
      default: '#073642',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  }, 'dark');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/json/ankiety.json');
        const json = await response.json();
        setData(json.data);
      } catch (error) {
        console.error('Błąd pobierania danych :', error);
      }
      setProgressPending(false);
    };
    fetchData();
  }, []);

  const ExpandedComponent = ({ data }) => (
    <div style={{ fontSize: '80%', margin: '0.5cm' }}>
      {data.questionnaireDescription}
    </div>
  );

  const FilterComponent = () => (
    <div className="shadow p-2 mb-3 bg-body rounded">
      Zaawansowane filtry 
    </div>
  );

  const [filterText, setFilterText] = useState('');
  const filteredItems = data.filter(
    item => item.questionnaireName && item.questionnaireName.toLowerCase().includes(filterText.toLowerCase()),
  );

  return (
    <div className="shadow p-3 mb-1 bg-body rounded">
      <DataTable
        title="Dodatkowe informacje o tabeli"
        columns={columns}
        data={filteredItems}
        fixedHeader
        fixedHeaderScrollHeight="500px"
        expandableRows
        expandableRowsComponent={ExpandedComponent}
        conditionalRowStyles={conditionalRowStyles}
        returnButton={false}
        //theme="solarized"
        progressPending={progressPending}
        subHeader
        subHeaderComponent={<FilterComponent
        />}
      >
      </DataTable>
    </div>
  );
}

export default DataTableAnkiety;


